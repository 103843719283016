import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import booleanSwitch from '../tools/boolean-switch';
import calcTheLargest from '../tools/calc-the-largest';
import calcTheLeast from '../tools/calc-the-least';
import changeStudioValue from '../tools/change-studio-value';
import getSelectedParams from '../tools/get-selected-params';
import resetSortingParameter from '../tools/reset-sorting-parameter';
import sortAbc from '../tools/sort-abc';
import sortDate from '../tools/sort-date';
import sortNumber from '../tools/sort-number';
import { BlockIds, DOMAIN_RELATIVE } from '../utils/constants';
import { convertDateIntoMonthAndYear, capitalizeFirstLetter } from '../utils/helpers/helpers.jsx';
import { comparedFlatsApi } from './ComparedFlatsSlice';
import { favoritesApi } from './FavoritesSlice';
import {
  apiRoutes,
  fetchCatalog,
  fetchProjectsByRole,
  flatCheck,
  fetchResidentialWithFloors,
  fetchAttributesList,
} from './api/api';

const initialState = {
  projects: [],
  urls: {
    getResidential: {
      url: apiRoutes.urlCatalog,
      initial: false,
      status: 'idle',
      error: null,
    },
    getResidentialWithFloors: {
      status: 'idle',
    },
  },
  //Параметры фильтрации по чекбоксу
  filterParameters: {
    byCheckbox: {
      redPrice: [{ name: 'Квартира по акции', value: 'redPrice', active: false }],
      upToReserve: [{ name: 'Доступно для бронирования', value: 'upToReserve', active: false }],
      rooms: [
        { name: 'СТ', value: '0', active: false, disabled: false },
        { name: '1', value: '1', active: false, disabled: false },
        { name: '2', value: '2', active: false, disabled: false },
        { name: '3', value: '3', active: false, disabled: false },
        { name: '4', value: '4', active: false, disabled: false },
        { name: '5', value: '5', active: false, disabled: false },
      ],
      settlementDate: [],
      bulk: [],
      houseName: [
        { name: 'reds', value: 'reds', active: false, disabled: false },
        { name: 'platinum', value: 'platinum', active: false, disabled: false },
        { name: 'gold', value: 'gold', active: false, disabled: true },
        { name: 'green', value: 'green', active: false, disabled: true },
      ],
      sections: [],
      advantages: [],
      finish: [
        { name: 'Финишная', value: 'finish', active: false, empty: null, disabled: false },
        { name: 'Без отделки', value: 'woWhitebox', active: false, empty: null, disabled: false },
        { name: 'White Box Max', value: 'whiteBoxMax', active: false, empty: null, disabled: false },
        {
          name: 'Дизайнерская отделка',
          value: 'dizajnerskayaOtdelkaForma',
          active: false,
          empty: null,
          disabled: false,
        },
      ],
    },
    advantagesSvgIcons: [
      // 'oknoVVannoj',
      'highCeiling',
      // 'cornerGlazing',
      // 'fireplace',
      'livingKitchen',
      'loggia',
      // 'panoramicGlazing',
      'patio',
      // 'penthouse',
      // 'vidNaReku',
      'vidVoDvor',
      // 'sauna',
      // 'terrace',
      'dressingRoom',
      // 'balcony',
      'bath',
      // 'countOfBathrooms',
      'laundry',
      'masterBedroom',
      // 'notfirstfloor',
      'roomOnEast',
      'roomOnNorth',
      'roomOnSouth',
    ],
    selectedParams: {},
    //Параметры фильтрации по range-слайдерам
    //Крайние значения слайдеров
    slidersExtremeValues: {
      price: [0, 10],
      area: [0, 10],
      floor: [0, 10],
    },
    //Текущие значения слайдеров
    slidersValues: {
      price: [0, 10],
      area: [0, 10],
      floor: [0, 10],
    },
    //Значения инпутов
    inputsValues: {
      price: [0, 10],
      area: [0, 10],
      floor: [0, 10],
    },
    //Значения ползунков в фильтре по умолчанию
    initialValues: {
      area: [0, 10],
      price: [0, 10],
      floor: [0, 10],
    },
    inactiveSliders: {
      floor: false,
      price: false,
      area: false,
    },
    //Возможные параметры фильтрации
    params: ['floor', 'price', 'area'],
    //Параметры, которые были выбраны пользователем
    interactedParams: {
      floor: {
        min: false,
        max: false,
      },
      price: {
        min: false,
        max: false,
      },
      area: {
        min: false,
        max: false,
      },
    },
  },
  //Возможные параметры фильтрации
  params: ['floor', 'price', 'area'],
  amountOfSelectedParams: 0,
  amountSelectedRooms: 0,
  amountSelectedHouseName: 0,
  amountSelectedAdvantages: 0,
  amountSelectedFinish: 0,
  amountSelectedRange: {
    floor: 0,
    price: 0,
    area: 0,
  },
  //Колонки таблицы для сортировки
  sortColumns: {
    scheme: 'План',
    number: 'Кв. №',
    rooms: 'Спальни',
    area: 'Площадь',
    floor: 'Этаж',
    section_number: 'Секция',
    houseName: 'Дом',
    finish: 'Отделка',
    advantages: 'Особенности',
    price: 'Цена',
  },
  //Текущий параметр сортировки
  sortParameters: {
    value: 'area',
    direction: 'asc_',
  },
  //Опции для сортировки выпадающим списком
  dropdownOptions: [
    { value: 'asc-price', label: ' По росту стоимости' },
    { value: 'dec-price', label: 'По убыванию стоимости' },
    { value: 'asc-area', label: 'По росту площади' },
    { value: 'dec-area', label: 'По убыванию площади' },
    { value: 'asc-sort', label: 'Сортировка' },
  ],
  //Текущая опция сортировки
  selectedOption: { value: 'asc-area', label: 'По возрастанию площади' },
  potentialUniqueAttributesFlat: [''],
  //Места вызова функции
  //по клику на заголовок столбца таблицы
  inTableHead: 'inTableHead',
  //после изменения состояния формы
  inForm: 'inForm',
  //Сокращение диапазона фильтрации?
  rangeReduction: false,
  //Пагинация каталога на мобике
  countPage: 1,
  perPage: 5,
  bulks: [],
  currentBulk: 'reds',
  currentBulkId: 0,
  currentFloor: 0,
  currentFlat: 0,
  //Квартиры, которые выводятся на страницу
  shownFlats: [],
  //Все квартиры, взятые с сервера
  allFlats: [],
  //Избранное
  favoriteFlats: [],
  //Сравнение
  comparedFlats: [],
  //Квартиры после фильтрации чекбоксами
  filteredByCheckboxFlats: [],
  //Список айдишников свободных квартир из тех, что хранились в избранном
  freeFlatsId: [],
  //Отфильтрован список квартир?
  isFiltered: null,
  //Особенности выбранной квартиры
  currentAdvantages: [],
  //Статус квартиры
  flatStatus: {
    free: 'free',
    reserve: 'reserve',
  },
  catalogPageDisplay: 'grid',
  isFiltersModalOpen: false,
  settlementDateFilter: null,
  projectData: {},
  currentFlatProject: '',
  bulksWithFloors: [],
};

const catalogSlice = createSlice({
  name: 'flatsSlice',
  initialState,
  reducers: {
    //Получение актуальных характеристик квартир, по которым возможна фильтрация
    checkRelevantAttributes(state) {
      const advantages = [];

      //Получение уникальных attributes;
      state.allFlats.forEach(item => {
        Object.keys(item.attributes).forEach(elem => {
          if (!advantages.find(advantage => advantage === elem)) {
            advantages.push(elem);
          }
        });
      });

      state.potentialUniqueAttributesFlat = advantages;

      //Если характеристика не заполнена ни у одной квартиры, то делаем её недоступной
      state.filterParameters.byCheckbox.advantages?.forEach(item => {
        const filtered = state.allFlats.filter(elem => {
          if (elem.attributes[`${item?.value}`] !== undefined) {
            return elem.attributes[`${item.value}`];
          }
          return null;
        });
        if (item) item.empty = filtered?.length === 0;
      });
    },
    //Изменить активное состояние параметра в фильтре по чекбоксам
    toggleActiveSearchParams(state, action) {
      const possibleParameters = Object.keys(state.filterParameters.byCheckbox);

      for (const param of possibleParameters) {
        if (action.payload.id === 'redPrice') {
          state.filterParameters.byCheckbox.redPrice[0].active = !state.filterParameters.byCheckbox.redPrice[0].active;
          catalogSlice.caseReducers.filterFlats(state);
          return;
        }
        if (action.payload.id.includes(param)) {
          booleanSwitch(state.filterParameters.byCheckbox, param, 'active', action.payload.value);
          catalogSlice.caseReducers.filterFlats(state);
          return;
        }
      }

      booleanSwitch(state.filterParameters.byCheckbox, 'advantages', 'active', action.payload.value);
      catalogSlice.caseReducers.filterFlats(state);
    },

    setSingleSearchParams(state, action) {
      for (const param of state.filterParameters.byCheckbox[action.payload.id]) {
        if (param.value === action.payload.value && param.disabled === false) {
          param.active = true;
        } else {
          param.active = false;
        }
      }

      catalogSlice.caseReducers.filterFlats(state);
    },

    filterFlats(state) {
      let amountOfSelectedParams = 0;
      //Фильтрация по range-слайдерам
      const interactedParams = state.filterParameters.interactedParams;

      const slidersValues = {
        price: [0, 0],
        area: [0, 0],
        floor: [0, 0],
      };

      Object.keys(interactedParams).forEach(key => {
        if (interactedParams[key].min) {
          slidersValues[key][0] = state.filterParameters.slidersValues[key][0];
        } else {
          slidersValues[key][0] = state.filterParameters.initialValues[key][0];
        }

        if (interactedParams[key].max) {
          slidersValues[key][1] = state.filterParameters.slidersValues[key][1];
        } else {
          slidersValues[key][1] = state.filterParameters.initialValues[key][1];
        }

        if (interactedParams[key].max || interactedParams[key].min) {
          amountOfSelectedParams++;
        }
      });

      const bySliders = flat => {
        return (
          flat.currentPrice >= slidersValues.price[0] &&
          flat.currentPrice <= slidersValues.price[1] &&
          flat.area >= slidersValues.area[0] &&
          flat.area <= slidersValues.area[1] &&
          flat.floor >= slidersValues.floor[0] &&
          flat.floor <= slidersValues.floor[1]
        );
      };

      let filtered = state.allFlats.filter(bySliders);

      //Получение актуальных параметров для фильтрации
      state.filterParameters.selectedParams = {
        rooms: getSelectedParams(state.filterParameters.byCheckbox, 'rooms').replace('0', 'studio'),
        houseName: getSelectedParams(state.filterParameters.byCheckbox, 'houseName'),
        sections: getSelectedParams(state.filterParameters.byCheckbox, 'sections'),
        finish: getSelectedParams(state.filterParameters.byCheckbox, 'finish').split(','),
        advantages: getSelectedParams(state.filterParameters.byCheckbox, 'advantages').split(','),
        bulk: getSelectedParams(state.filterParameters.byCheckbox, 'bulk'),
        settlementDate: getSelectedParams(state.filterParameters.byCheckbox, 'settlementDate'),
      };

      //Делаем все не выбранные характеристики недоступными
      Object.keys(state.filterParameters.byCheckbox).forEach(key => {
        state.filterParameters.byCheckbox[key].forEach(item => {
          item.disabled = !item.active;
        });
      });

      const byRooms = flat => {
        return state.filterParameters.selectedParams.rooms
          ? state.filterParameters.selectedParams.rooms.includes(flat.rooms.toString())
          : true;
      };

      const bySections = flat => {
        return state.filterParameters.selectedParams.sections
          ? state.filterParameters.selectedParams.sections.includes(flat.section.number)
          : true;
      };

      const byHouseName = flat => {
        return state.filterParameters.selectedParams.houseName
          ? state.filterParameters.selectedParams.houseName.toLowerCase().includes(flat.houseName.toLowerCase())
          : true;
      };

      const byAdvantages = flat => {
        for (let i = 0; i < state.filterParameters.selectedParams.advantages.length; i++) {
          if (!flat.attributes[state.filterParameters.selectedParams.advantages[i]]) {
            return false;
          }
        }
        return true;
      };

      const byFinish = flat => {
        let flatIsSuitable = false;
        const selectedFinishes = state.filterParameters.selectedParams.finish;
        // проверяем наличие whiteBox
        const hasWhiteBoxMaxFilter = selectedFinishes.includes('whiteBoxMax');

        for (let i = 0; i < selectedFinishes.length; i++) {
          const finish = selectedFinishes[i];

          // если фильтр - whiteBoxMax и у квартиры есть атрибут WhiteBox
          // то показываем whiteBoxMax
          if (hasWhiteBoxMaxFilter && flat.attributes['whiteBox']) {
            flatIsSuitable = true;
            break;
          }

          // проверка на соответствие обычным фильтрам
          if (flat.attributes[finish]) {
            flatIsSuitable = true;
            break;
          }
        }

        return flatIsSuitable;
      };

      const byBulk = flat => {
        return state.filterParameters.selectedParams.bulk
          ? state.filterParameters.selectedParams.bulk.includes(flat.bulk.id)
          : true;
      };

      const bySettlementDate = flat => {
        return state.filterParameters.selectedParams.settlementDate
          ? state.filterParameters.selectedParams.settlementDate.includes(flat.bulk.keyDate)
          : true;
      };

      const byCheckbox = {
        rooms: byRooms,
        houseName: byHouseName,
        sections: bySections,
        advantages: byAdvantages,
        finish: byFinish,
        bulk: byBulk,
        settlementDate: bySettlementDate,
      };

      const findRoom = flat => {
        state.filterParameters.byCheckbox.rooms.forEach(item => {
          if ((item.value === '0' && flat.rooms === 'studio') || Number(flat.rooms) === Number(item.value)) {
            item.disabled = false;
          }
        });
      };

      const findHouseName = flat => {
        state.filterParameters.byCheckbox.houseName?.forEach(item => {
          if (flat.houseName?.toLowerCase() === item.value.toLowerCase()) {
            item.disabled = false;
          }
        });
      };

      const findSection = flat => {
        state.filterParameters.byCheckbox.sections.forEach(item => {
          if (flat.section.number === Number(item.value)) {
            item.disabled = false;
          }
        });
      };

      const findAdvantage = flat => {
        state.filterParameters.byCheckbox.advantages.forEach(item => {
          if (flat.attributes[item.value]) {
            item.disabled = false;
          }
        });
      };

      const findFinish = flat => {
        state.filterParameters.byCheckbox.finish.forEach(item => {
          // если в квартире whiteBox
          // показывем фильтр whiteBoxMax
          // whiteBox в проекте везде замещается whiteBoxMax'ом
          if (flat.attributes[item.value] || (item.value === 'whiteBoxMax' && flat.attributes['whiteBox'])) {
            item.disabled = false;
          }
        });
      };

      const findBulk = flat => {
        state.filterParameters.byCheckbox.bulk.forEach(item => {
          if (flat.bulk.id === item.value) {
            item.disabled = false;
          }
        });
      };

      const findSettlementDate = flat => {
        state.filterParameters.byCheckbox.settlementDate.forEach(item => {
          if (flat.bulk.keyDate === item.value) {
            item.disabled = false;
          }
        });
      };

      const findRedPrice = flat => {
        state.filterParameters.byCheckbox.redPrice.forEach(item => {
          if (flat.redPrice || flat.promo) {
            item.disabled = false;
          }
        });
      };

      const findCheckbox = {
        rooms: findRoom,
        houseName: findHouseName,
        sections: findSection,
        advantages: findAdvantage,
        finish: findFinish,
        bulk: findBulk,
        settlementDate: findSettlementDate,
        redPrice: findRedPrice,
      };

      const params = ['rooms', 'houseName', 'sections', 'advantages', 'finish', 'bulk', 'settlementDate'];
      const chosenParams = [];
      const selectedParamsKeys = Object.keys(state.filterParameters.selectedParams);
      selectedParamsKeys.forEach(key => {
        const param = state.filterParameters.selectedParams[key];
        if ((param && param.length === 0) || (param.length > 0 && param[0])) {
          chosenParams.push(key);
          if (key !== 'advantages') {
            amountOfSelectedParams++;
          } else {
            amountOfSelectedParams += param.length;
          }
        }
      });

      state.amountOfSelectedParams = amountOfSelectedParams;

      const amountOfChosenParams = chosenParams.length;

      if (amountOfChosenParams === 0) {
        state.shownFlats = filtered;
        catalogSlice.caseReducers.setAvailableCheckboxes(state);
      }

      if (amountOfChosenParams === 1) {
        if (chosenParams[0] !== 'advantages') {
          filtered.forEach(flat => {
            findCheckbox[chosenParams[0]](flat);
          });
        }

        const restOfParams = params.filter(param => param !== chosenParams[0] && param !== 'advantages');

        state.shownFlats = filtered.filter(byCheckbox[chosenParams[0]]);
        state.shownFlats.forEach(flat => {
          findCheckbox.advantages(flat);
          restOfParams.forEach(param => {
            findCheckbox[param](flat);
          });
        });
      }

      if (amountOfChosenParams >= 2) {
        for (const param of chosenParams) {
          if (param !== 'advantages') {
            const otherParams = chosenParams.filter(p => p !== param);
            let filteredByOtherParams = filtered;
            otherParams.forEach(otherParam => {
              filteredByOtherParams = filteredByOtherParams.filter(byCheckbox[otherParam]);
            });
            filteredByOtherParams.forEach(flat => {
              findCheckbox[param](flat);
            });
          }
        }

        chosenParams.forEach(chosenParam => {
          filtered = filtered.filter(byCheckbox[chosenParam]);
        });

        state.shownFlats = filtered;

        const restOfParams = params.filter(param => !chosenParams.includes(param) && param !== 'advantages');

        state.shownFlats.forEach(flat => {
          findCheckbox.advantages(flat);
          restOfParams.forEach(param => {
            findCheckbox[param](flat);
          });
        });
      }

      const byRedPrice = flat => {
        return state.filterParameters.byCheckbox.redPrice[0].active ? flat.redPrice || flat.promo : true;
      };

      const byUpToReserve = flat => {
        return state.filterParameters.byCheckbox.upToReserve[0].active ? flat.booking_status === 'active' : true;
      };

      state.shownFlats = state.shownFlats.filter(byRedPrice).filter(byUpToReserve);

      catalogSlice.caseReducers.setExtremeSlidersValues(state);
    },

    //Сбросить состояние списка квартир до первоначального
    resetFilters(state) {
      state.amountOfSelectedParams = 0;
      const checkboxParams = Object.keys(state.filterParameters.byCheckbox);

      checkboxParams.forEach(item => {
        state.filterParameters.byCheckbox[item] = resetSortingParameter(state.filterParameters.byCheckbox[item]);
      });

      const interactedParams = Object.keys(state.filterParameters.interactedParams);
      interactedParams.forEach(item => {
        state.filterParameters.interactedParams[item].max = false;
        state.filterParameters.interactedParams[item].min = false;
      });

      state.shownFlats = state.allFlats;
      state.filterParameters.selectedParams = {};
      catalogSlice.caseReducers.setExtremeSlidersValues(state);
      catalogSlice.caseReducers.setAvailableCheckboxes(state);
    },

    //Отсортировать квартиры по клику на заголовок таблицы
    sortFlats(state, action) {
      const value = action.payload.value;
      // Исключаем фильтр по характеристикам/отделке/схеме
      if (value !== 'advantages' && value !== 'finish' && value !== 'scheme') {
        let direction = state.sortParameters.direction;

        if (action.payload.placeCall === state.inTableHead) {
          //Если вызов произошел по клику на заголовок таблицы
          //переключить направления сортировки
          if (direction === 'asc_') {
            direction = 'desc_';
          } else {
            direction = 'asc_';
          }
        }

        state.sortParameters = {
          value: action.payload.value,
          direction,
        };

        const keyTyped = action.payload.array;
        const stateArray = state[keyTyped];

        if (Array.isArray(stateArray) && stateArray.length > 0) {
          if (action.payload.value === 'rooms') {
            const array = stateArray.map(flat => changeStudioValue(flat, 'studio', 0));
            sortNumber(array, state.sortParameters.value, state.sortParameters.direction);
            state[keyTyped] = array.map(flat => changeStudioValue(flat, 0, 'studio'));
          } else if (action.payload.value === 'section_number') {
            sortNumber(stateArray, 'section.number', state.sortParameters.direction);
          } else if (action.payload.value === 'bulk_number') {
            sortNumber(stateArray, 'bulk.number', state.sortParameters.direction);
          } else if (action.payload.value === 'date') {
            sortDate(stateArray, 'bulk.keyDate', state.sortParameters.direction);
          } else if (action.payload.value === 'houseName') {
            sortAbc(stateArray, state.sortParameters.value, state.sortParameters.direction);
          } else {
            sortNumber(
              stateArray,
              state.sortParameters.value.replace('price', 'currentPrice'),
              state.sortParameters.direction,
            );
          }
        }
      }
    },
    //Сменить текущую опцию сортировки
    changeSelectedDropdownOption: (state, action) => {
      if (action.payload) {
        // @ts-ignore
        state.selectedOption = action.payload;
      }
    },
    //Отсортировать квартиры по выпадающему списку
    sortFlatsListByDropdown: (state, action) => {
      const flatsArray = action.payload.array;

      if (typeof action.payload.value.value === 'string') {
        if (action.payload.value.value.includes('asc-') || action.payload.value.value.includes('dec-')) {
          const direction = `${action.payload.value.value.slice(0, 3)}_`;
          const params = action.payload.value.value.replace('asc-', '').replace('dec-', '');
          if (params === 'rooms') {
            const array = state[flatsArray].map(flat => changeStudioValue(flat, 'studio', '0'));
            sortNumber(array, 'rooms', direction);
            state[flatsArray] = array.map(flat => changeStudioValue(flat, '0', 'studio'));
          } else if (params === 'houseName') {
            sortAbc(state[flatsArray], 'houseName', direction);
          } else {
            sortNumber(state[flatsArray], params.replace('price', 'currentPrice'), direction);
          }
        } else {
          const withWhiteBox = state[flatsArray].filter(item => item.attributes.whiteBox === true);
          const withoutWhiteBox = state[flatsArray].filter(item => item.attributes.whiteBox === false);

          if (action.payload.value.value.includes('with-')) {
            state[flatsArray] = withWhiteBox.concat(withoutWhiteBox);
          } else {
            state[flatsArray] = withoutWhiteBox.concat(withWhiteBox);
          }
        }
      }
    },
    //Пагинация каталога на мобилке
    setCount: (state, action) => {
      if (action.payload !== null) {
        state.countPage = action.payload;
      } else {
        state.countPage += 1;
      }
    },
    //Записать текущую секцию по houseName
    setCurrentBulk: (state, action) => {
      state.currentBulk = action.payload;
    },
    //Записать текущую секцию и bulk_id
    setCurrentBulkId: (state, action) => {
      state.currentBulkId = action.payload;
    },
    //Записать текущий этаж
    setCurrentFloor(state, action) {
      state.currentFloor = action.payload;
    },
    setCurrentFlat(state, action) {
      state.currentFlat = action.payload;
    },
    changeCatalogPageDisplay(state, action) {
      state.catalogPageDisplay = action.payload;
    },
    changeCurrentFlatProject(state, action) {
      state.currentFlatProject = action.payload;
    },

    setAvailableCheckboxes(state) {
      //Делаем все не выбранные характеристики недоступными
      Object.keys(state.filterParameters.byCheckbox)?.forEach(key => {
        state.filterParameters.byCheckbox[key]?.forEach(item => {
          if (item) item.disabled = !item?.active;
        });
      });
      //Если характеристика заполнена хотя бы у одной квартиры, то делаем её доступной
      for (let i = 0; i < state.shownFlats.length; i++) {
        const flat = state.shownFlats[i];

        Object.keys(flat.attributes)?.forEach(key => {
          if (flat.attributes[key]) {
            state.filterParameters.byCheckbox.advantages?.forEach(item => {
              if (item?.value === key) {
                item.disabled = false;
              }
            });
            state.filterParameters.byCheckbox.finish.forEach(item => {
              if (item.value === key || (item?.value === 'whiteBoxMax' && key === 'whiteBox')) {
                item.disabled = false;
              }
            });
          }
        });
        state.filterParameters.byCheckbox.houseName?.forEach(item => {
          if (flat.houseName?.toLowerCase() === item.value.toLowerCase()) {
            item.disabled = false;
          }
        });
        state.filterParameters.byCheckbox.sections.forEach(item => {
          if (flat.section.number === Number(item.value)) {
            item.disabled = false;
          }
        });
        state.filterParameters.byCheckbox.rooms.forEach(item => {
          if ((item.value === '0' && flat.rooms === 'studio') || flat.rooms === Number(item.value)) {
            item.disabled = false;
          }
        });
        state.filterParameters.byCheckbox.redPrice.forEach(item => {
          if (flat.redPrice || flat.promo) {
            item.disabled = false;
          }
        });
        state.filterParameters.byCheckbox.upToReserve.forEach(item => {
          if (flat.bookingStatus === 'active') {
            item.disabled = false;
          }
        });
        state.filterParameters.byCheckbox.settlementDate.forEach(item => {
          if (flat.bulk.keyDate === item.value) {
            item.disabled = false;
          }
        });
        state.filterParameters.byCheckbox.bulk.forEach(item => {
          if (flat.bulk.id === item.value) {
            item.disabled = false;
          }
        });
      }
    },
    setExtremeSlidersValues(state) {
      if (state.allFlats.length === 0) {
        state.filterParameters.params.forEach(param => {
          const maxParam = state.filterParameters.initialValues[param][1];
          const minParam = state.filterParameters.initialValues[param][0];

          state.filterParameters.slidersExtremeValues[param] = [minParam, maxParam];
          state.filterParameters.slidersValues[param] = [minParam, maxParam];
          state.filterParameters.inputsValues[param] = [minParam, maxParam];
        });
      }

      // Расчет максимумов и минимумов для слайдеров с обновленными данными
      state.filterParameters.params.forEach(param => {
        const interactedParams = state.filterParameters.interactedParams[param];

        const largestValue = calcTheLargest(state.shownFlats, param);
        const leastValue = calcTheLeast(state.shownFlats, param, largestValue);

        const maxParam = param !== 'price' ? Math.ceil(largestValue) : Math.ceil(largestValue / 1000000) * 1000000;
        const minParam = param !== 'price' ? Math.floor(leastValue) : Math.floor(leastValue / 1000000) * 1000000;

        if (
          (maxParam - minParam === 1 ||
            (param === 'price' && maxParam - minParam === 1000000) ||
            minParam === maxParam) &&
          minParam !== 0
        ) {
          const [initialMinParam, initialMaxParam] = state.filterParameters.initialValues[param];

          if (!interactedParams.min && !interactedParams.max) {
            state.filterParameters.inactiveSliders[param] = true;
            state.filterParameters.slidersValues[param] = [initialMinParam, initialMaxParam];
            state.filterParameters.inputsValues[param] = [minParam, maxParam];
            state.filterParameters.slidersExtremeValues[param] = [initialMinParam, initialMaxParam];
          }
        } else if (minParam !== 0 && minParam !== maxParam) {
          state.filterParameters.inactiveSliders[param] = false;

          if (!interactedParams.min) {
            state.filterParameters.slidersValues[param][0] = minParam;
            state.filterParameters.inputsValues[param][0] = minParam;
            state.filterParameters.slidersExtremeValues[param][0] = minParam;
          }

          if (!interactedParams.max) {
            state.filterParameters.slidersValues[param][1] = maxParam;
            state.filterParameters.inputsValues[param][1] = maxParam;
            state.filterParameters.slidersExtremeValues[param][1] = maxParam;
          }
        } else if (minParam === 0 && minParam === maxParam && !interactedParams.min && !interactedParams.max) {
          const [lastExtremeValueMin, lastExtremeValueMax] = state.filterParameters.slidersExtremeValues[param];

          state.filterParameters.inactiveSliders[param] = true;
          state.filterParameters.slidersValues[param] = [lastExtremeValueMin, lastExtremeValueMax];
          state.filterParameters.inputsValues[param] = [lastExtremeValueMin, lastExtremeValueMax];
        }
      });
    },

    updateSliderValues(state, action) {
      //Проверка на изменение значений - если значения отличаются от максимальных/минимальных, то
      //пользователь взаимодействовал с ползунками, записываем это в interactedParams и
      //записываем новые значения в slidersValues
      //иначе - пользователь вернул значения в исходное положение, записываем false в interactedParams
      //и записываем значения из initialValues в slidersValues

      if (Number(action.payload.values[0]) !== state.filterParameters.slidersExtremeValues[action.payload.slider][0]) {
        state.filterParameters.interactedParams[action.payload.slider].min = true;
        state.filterParameters.slidersValues[action.payload.slider][0] = Number(action.payload.values[0]);
      } else {
        state.filterParameters.interactedParams[action.payload.slider].min = false;
        state.filterParameters.slidersValues[action.payload.slider][0] =
          state.filterParameters.initialValues[action.payload.slider][0];
      }

      if (Number(action.payload.values[1]) !== state.filterParameters.slidersExtremeValues[action.payload.slider][1]) {
        state.filterParameters.interactedParams[action.payload.slider].max = true;
        state.filterParameters.slidersValues[action.payload.slider][1] = Number(action.payload.values[1]);
      } else {
        state.filterParameters.interactedParams[action.payload.slider].max = false;
        state.filterParameters.slidersValues[action.payload.slider][1] =
          state.filterParameters.initialValues[action.payload.slider][1];
      }

      catalogSlice.caseReducers.filterFlats(state);
    },
    //Обновить значения для отображения в изменяемом range-слайдере
    updateInputValues(state, action) {
      //Записываем новые значения в активный слайдер
      state.filterParameters.inputsValues[action.payload.slider][0] = Number(action.payload.values[0]);
      state.filterParameters.inputsValues[action.payload.slider][1] = Number(action.payload.values[1]);
    },

    //открытие модального окна
    openModal: state => {
      state.isFiltersModalOpen = true;
    },
    // закрытие модального окна
    closeModal: state => {
      state.isFiltersModalOpen = false;
    },

    setSettlementDateFilter: (state, action) => {
      state.settlementDateFilter = action.payload;
    },

    updateMultipleFilterParams(state, action) {
      const { rooms, sections, finish, area, floor, price, advantages } = action.payload;
      const sliders = [
        area ? { slider: 'area', newValues: area } : null,
        floor ? { slider: 'floor', newValues: floor } : null,
        price ? { slider: 'price', newValues: price } : null,
      ].filter(slider => slider !== null);

      const roomsReformatted = rooms
        ? rooms.flatMap(room => {
            if (room === 'Ст') {
              return '0';
            }
            if (room.includes('+')) {
              return state.filterParameters.byCheckbox.rooms
                .map(({ value }) => value)
                .filter(value => Number(value) > Number(room.replace('+', '')));
            }
            return room;
          })
        : null;

      const checkboxes = [
        rooms ? { checkbox: 'rooms', newValues: roomsReformatted } : null,
        sections ? { checkbox: 'sections', newValues: sections } : null,
        finish ? { checkbox: 'finish', newValues: finish } : null,
        advantages ? { checkbox: 'advantages', newValues: advantages } : null,
      ].filter(checkbox => checkbox !== null);

      catalogSlice.caseReducers.updateMultipleSliders(state, { payload: sliders });
      catalogSlice.caseReducers.updateMultipleCheckboxes(state, { payload: checkboxes });
    },
    updateMultipleCheckboxes(state, action) {
      const checkboxes = action.payload;

      for (const { checkbox, newValues } of checkboxes) {
        for (const value of newValues) {
          catalogSlice.caseReducers.toggleActiveSearchParams(state, { payload: { id: checkbox, value } });
        }
      }
    },
    updateMultipleSliders(state, action) {
      const sliders = action.payload;

      sliders.forEach(({ slider, newValues }) => {
        let x = newValues[0];
        let y = newValues[1];

        x = Math.ceil(x);
        y = Math.floor(y);

        if (slider === 'price') {
          x *= 1000000;
          y *= 1000000;
        }

        if (x < Number(state.filterParameters.slidersExtremeValues[slider][0])) {
          x = state.filterParameters.slidersExtremeValues[slider][0];
        }
        if (x > Number(state.filterParameters.slidersExtremeValues[slider][1])) {
          x = state.filterParameters.slidersExtremeValues[slider][1];
        }

        if (y > Number(state.filterParameters.slidersExtremeValues[slider][1])) {
          y = state.filterParameters.slidersExtremeValues[slider][1];
        }
        if (y < Number(state.filterParameters.slidersExtremeValues[slider][0])) {
          y = state.filterParameters.slidersExtremeValues[slider][0];
        }

        const values = [x, y];

        catalogSlice.caseReducers.updateInputValues(state, { payload: { slider, values } });
        catalogSlice.caseReducers.updateSliderValues(state, { payload: { slider, values } });
      });
    },

    filterStateFromSearchParams(state) {
      const filtersByCheckbox = state.filterParameters.byCheckbox;
      const searchParams = new URLSearchParams(window.location.search);

      if (!searchParams.size) return;

      for (const filterParam in filtersByCheckbox) {
        if (Object.hasOwn(filtersByCheckbox, filterParam)) {
          const filterParamValues = searchParams.get(filterParam)?.split(',');

          if (!filterParamValues) continue;

          for (const value of filterParamValues) {
            const filterParamObj = filtersByCheckbox[filterParam].find(param => String(param.value) === value);

            if (filterParamObj && !filterParamObj.active) {
              catalogSlice.caseReducers.toggleActiveSearchParams(state, {
                payload: {
                  id: filterParam,
                  value: filterParamObj.value,
                },
              });
            }
          }
        }
      }
    },
  },
  extraReducers: builder => {
    builder
      .addCase(fetchProjectsByRole.fulfilled, (state, action) => {
        state.projects = action.payload.projectsByRole.map(({ name, project_id }, index) => {
          const editedName = name.toLowerCase() === 'først' ? 'forst' : name.toLowerCase();
          const matchingProject = action.payload.bulkProjects.find(project => project.id === project_id);
          return {
            id: project_id,
            value: `${index}`,
            title: name,
            name: editedName,
            url:
              project_id === BlockIds.PORTA_BLOCK_ID || project_id === BlockIds.FRAME_BLOCK_ID
                ? `${DOMAIN_RELATIVE}/api/v2/commercial?blockId=${project_id}`
                : `${DOMAIN_RELATIVE}/api/v2/landing/flat?blockId=${project_id}`,
            active: project_id === BlockIds.FORST_BLOCK_ID,
            blockId: project_id,
            objectType: matchingProject.objectType,
          };
        });
      })
      .addCase(fetchResidentialWithFloors.pending, state => {
        state.urls.getResidentialWithFloors.status = 'pending';
      })
      .addCase(fetchResidentialWithFloors.fulfilled, (state, action) => {
        state.bulksWithFloors = action.payload.bulks;
        state.urls.getResidentialWithFloors.status = 'fulfilled';
      })
      .addCase(fetchResidentialWithFloors.rejected, state => {
        state.urls.getResidentialWithFloors.status = 'rejected';
      })
      .addCase(fetchCatalog.pending, state => {
        state.urls.getResidential.status = 'pending';
        state.urls.getResidential.error = null;
        console.log('Идет запрос на сервер...');
      })
      .addCase(fetchCatalog.fulfilled, (state, action) => {
        state.urls.getResidential.status = 'idle';
        const replaceNumberSixBulkWithUnique = action.payload.bulks.map(bulk => {
          if (bulk.unique && bulk.number === 6) {
            return { ...bulk, name: 'UNIQUE' };
          }
          return bulk;
        });

        state.bulks = replaceNumberSixBulkWithUnique;
        let freeFlats = action.payload.flats
          .filter(item => item.status === 'free')
          .map(item => {
            if (item.block_id === BlockIds.SOUL_BLOCK_ID && item.bulk.number === 6) {
              return { ...item, bulk: { ...item.bulk, name: 'UNIQUE' } };
            }
            return item;
          });

        state.filterParameters.byCheckbox.settlementDate = [];
        state.filterParameters.byCheckbox.bulk = [];

        for (const freeFlat of freeFlats) {
          const settlementDate = freeFlat.bulk.keyDate;
          if (
            state.filterParameters.byCheckbox.settlementDate.filter(item => item.value === settlementDate).length === 0
          ) {
            if (!settlementDate) return;

            const settlementDateItem = {
              name: `${convertDateIntoMonthAndYear(new Date(settlementDate))}`,
              value: settlementDate,
              disabled: false,
              active: false,
            };
            state.filterParameters.byCheckbox.settlementDate.push(settlementDateItem);
          }

          const bulkId = freeFlat.bulk.id;
          if (state.filterParameters.byCheckbox.bulk.filter(item => item.value === bulkId).length === 0) {
            const bulkName = freeFlat.bulk.name;

            const bulkItem = {
              name: bulkName,
              value: bulkId,
              disabled: false,
              active: false,
            };
            state.filterParameters.byCheckbox.bulk.push(bulkItem);
          }
        }

        const availableSections = new Set();

        for (const flat of freeFlats) {
          availableSections.add(flat.section.number);
        }

        state.filterParameters.byCheckbox.sections = Array.from(availableSections)
          .sort()
          .map(section => {
            return {
              name: section,
              value: section,
              active: false,
              disabled: false,
            };
          });

        // в advantages не должно быть отделки -> убираем
        const arr = action.payload.attributeList
          ?.filter(
            attr =>
              attr?.name !== 'whiteBoxMax' &&
              attr?.name !== 'whiteBox' &&
              attr?.name !== 'unfinished' &&
              attr?.name !== 'dizajnerskayaOtdelkaForma',
          )
          .map(attr => ({
            name: capitalizeFirstLetter(attr.description || attr.name),
            value: attr.name,
            active: false,
            empty: null,
            disabled: false,
            category: attr.category,
          }));
        state.filterParameters.byCheckbox.advantages = arr;

        state.allFlats = freeFlats;
        state.shownFlats = freeFlats;
        console.log('Квартиры загрузились');

        state.urls.getResidential.initial = true;
        state.urls.getResidential.status = 'fulfilled';

        const maxFloor = calcTheLargest(state.shownFlats, 'floor');
        const minFloor = calcTheLeast(state.shownFlats, 'floor', maxFloor);

        state.filterParameters.initialValues = {
          area: [Math.floor(action.payload.area.min), Math.ceil(action.payload.area.max)],
          price: [
            Math.floor(action.payload.price.min / 1000000) * 1000000,
            Math.ceil(action.payload.price.max / 1000000) * 1000000,
          ],
          floor: [minFloor, maxFloor],
        };

        catalogSlice.caseReducers.checkRelevantAttributes(state);
        catalogSlice.caseReducers.setExtremeSlidersValues(state);
        catalogSlice.caseReducers.setAvailableCheckboxes(state);

        const payload = {
          value: state.sortParameters.value,
          placeCall: state.inForm,
          array: 'shownFlats',
        };
        catalogSlice.caseReducers.sortFlats(state, { payload, type: '' });
        catalogSlice.caseReducers.filterStateFromSearchParams(state);
      })
      .addCase(fetchCatalog.rejected, (state, action) => {
        state.urls.getResidential.status = 'idle';
        console.log('Ошибка при запросе на сервер', action.payload);
        state.urls.getResidential.error = 'error';
        state.urls.getResidential.status = 'rejected';
      })
      .addCase(flatCheck.pending, () => {
        console.log('Идет проверка сохраненных квартир...');
      })
      .addCase(flatCheck.fulfilled, (state, action) => {
        //Собираем список свободных квартир
        state.freeFlatsId = Object.keys(action.payload).filter(item => action.payload[`${item}`]);
      })
      .addCase(flatCheck.rejected, (state, action) => {
        console.log('Ошибка при запросе на сервер', action.payload);
      })

      //Заполенине атрибутов для избранного
      .addCase(fetchAttributesList.pending, () => {
        console.log('Идет проверка сохраненных квартир...');
      })
      .addCase(fetchAttributesList.fulfilled, (state, action) => {
        state.filterParameters.byCheckbox.advantages = action.payload.data?.map(attr => {
          return {
            name: capitalizeFirstLetter(attr.description || attr.name),
            value: attr.name,
            active: false,
            empty: null,
            disabled: false,
            category: attr.category,
          };
        });
      })
      .addCase(fetchAttributesList.rejected, (state, action) => {
        console.log('Ошибка при запросе на сервер', action.payload);
      })

      .addCase(getProjectData.fulfilled, (state, action) => {
        state.projectData = action.payload;
      })
      //Удаляем из избранного
      .addMatcher(
        favoritesApi.endpoints.deleteFavoriteFlat.matchFulfilled,
        (
          state,
          {
            meta: {
              arg: { flatId },
            },
          },
        ) => {
          state.favoriteFlats = state.favoriteFlats.filter(flat => flat.id !== flatId);
        },
      )
      //Получаем квартиры в хуке из favoriteSlice и передаем его в favoriteFlats
      .addMatcher(favoritesApi.endpoints.getFavoritesFlats.matchFulfilled, (state, { payload }) => {
        state.favoriteFlats = payload.map(flat => flat.data);
      })
      .addMatcher(comparedFlatsApi.endpoints.getComparedFlats.matchFulfilled, (state, { payload }) => {
        state.comparedFlats = payload;
      });
  },
});

export default catalogSlice.reducer;

export const {
  toggleActiveSearchParams,
  setSingleSearchParams,
  changeAmountFilters,
  resetFilters,
  sortFlats,
  changeSelectedDropdownOption,
  sortFlatsListByDropdown,
  setCount,
  setCurrentBulk,
  setCurrentBulkId,
  setCurrentFloor,
  setCurrentFlat,
  changeCatalogPageDisplay,
  updateInputValues,
  updateSliderValues,
  openModal,
  closeModal,
  setSettlementDateFilter,
  updateMultipleFilterParams,
  changeCurrentFlatProject,
  filterStateFromSearchParams,
} = catalogSlice.actions;

export const sliceBulksWithFloors = state => state.catalogPage.bulksWithFloors;
export const isResidentialWithFloorsFulfilled = state =>
  state.catalogPage.urls.getResidentialWithFloors.status === 'fulfilled';

export const getProjectData = createAsyncThunk('catalog/projectData', async url => {
  const response = await fetch(url, {
    method: 'GET',
  });
  return await response.json();
});
