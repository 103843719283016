export const accreditationList = import.meta.env.VITE_ACCREDITATION_19993 === 'true';
export const workingSchedule = import.meta.env.VITE_WORK_SCHEDULE === 'true';
export const partnerPrograms = import.meta.env.VITE_PARTNER_PROGRAMS_21505 === 'true';
export const calculatorFreeTab = import.meta.env.VITE_CALCULATOR_FREE_TAB_21868 === 'true';
export const calculatorFreeTabNew = import.meta.env.VITE_CALCULATOR_FREE_TAB_NEW_15606 === 'true';
export const bookingRedirectToPartner = import.meta.env.VITE_BOOKING_REDIRET_TO_PARTNER_23195 === 'true';
export const newCdnForRetailProjects = import.meta.env.VITE_NEW_CDN_FOR_RETAIL_PROJECTS_23390 === 'true';
export const pdfButtonInCalculatorForFree = import.meta.env.VITE_PDF_DOWNLOAD_AND_SEND_TO_MAIL_23657 === 'true';


// СТарые флаги
export const commercialFormEnabled = import.meta.env.VITE_COMMERCIAL_FORM_16221 === 'true';
export const catalogRetail = import.meta.env.VITE_CATALOG_RETAIL_21560 === 'true';


