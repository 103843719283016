import { useEffect } from 'react';
import { useGetAllBenefits } from './calculator-logic/useGetAllBenefits';
import { useSelectBenefit } from './calculator-logic/useSelectBenefit';
import { useSelectFlatPrice } from './calculator-logic/useSelectFlatPrice';
import { useSelectFirstPayment } from './calculator-logic/useSelectFirstPayment';
import { useSelectTerm } from './calculator-logic/useSelectTerm';
import { useGetPriceCalcData } from './calculator-logic/useGetPriceCalcData';
import { useSwitchPercentOrSum } from './calculator-logic/useSetPercentOrSum';
import { changeOriginalBenefitsForSelect } from './utils/utils';

export const useCalculatorLogicForFree = (uniqueId, statusSetters) => {
  const { handleSetErrorStatus, handleSetLoadedStatus, handleSetPendingStatus } = statusSetters;

  const { benefits } = useGetAllBenefits({ handleSetErrorStatus, handleSetPendingStatus, handleSetLoadedStatus });

  const { benefit: currentBenefit, handleChangeBenefit } = useSelectBenefit([], benefits, uniqueId);

  const { flatPrice, handleChangeFlatPrice } = useSelectFlatPrice(uniqueId);

  const {
    initialPayment,
    firstPayment,
    handleChangeFirstPayment,
    handleChangeFirstPaymentCommitted,
    firstPaymentCommitted,
  } = useSelectFirstPayment(currentBenefit, flatPrice, uniqueId, statusSetters);

  const minFlatPrice = initialPayment?.initialPaymentData || 0;
  const maxFlatPrice = initialPayment?.maxInitialPayment || 1;
  const minFirstPayment = initialPayment?.initialPaymentData || 0;
  const maxFirstPayment = initialPayment?.maxInitialPayment || 1;
  const minTerm = currentBenefit?.minYears || 1;
  const maxTerm = currentBenefit?.maxYears || 1;

  const { term, handleChangeTerm, termCommitted, handleChangeTermCommitted } = useSelectTerm(
    uniqueId,
    currentBenefit?.maxYears,
    currentBenefit,
  );

  const { priceCalcData: calcData, handleGetPriceCalcData: handleGetCalcData } = useGetPriceCalcData(
    { minFirstPayment, maxFirstPayment },
    termCommitted,
    firstPaymentCommitted,
    flatPrice,
    currentBenefit,
    statusSetters,
  );

  const priceWithMargin = calcData?.priceWithMargin ?? initialPayment?.priceWithMargin;

  const { handleSwitchValue, isPercent, percentFirstPayment, handleChangePercentValue } = useSwitchPercentOrSum(
    handleChangeFirstPaymentCommitted,
    firstPayment,
    priceWithMargin,
  );

  useEffect(() => {
    handleGetCalcData();
  }, [handleGetCalcData]);

  return {
    benefits: changeOriginalBenefitsForSelect(benefits, uniqueId),
    currentBenefit,
    handleChangeBenefit,
    flatPrice,
    initialPayment,
    firstPayment,
    handleChangeFirstPayment,
    handleChangeFirstPaymentCommitted,
    firstPaymentCommitted,
    handleChangeFlatPrice,
    minFlatPrice,
    maxFlatPrice,
    minFirstPayment,
    maxFirstPayment,
    term,
    handleChangeTerm,
    termCommitted,
    handleChangeTermCommitted,
    minTerm,
    maxTerm,
    calcData,
    handleGetCalcData,
    handleSwitchValue,
    isPercent,
    percentFirstPayment,
    handleChangePercentValue,
    priceWithMargin,
  };
};
