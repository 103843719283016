import { createSlice } from '@reduxjs/toolkit';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { DOMAIN_RELATIVE } from '../utils/constants';
import { apiRoutes } from './api/api';
import { mockOperatingMode, mockVacationsManager } from '../mocks/meetingsSchedule';
import meetingsScheduleAdapter from '../adapters/meetingsScheduleAdapter';

const initialState = {
  operatingModeModal: false,
  createEventDialog: false,
  selectedRanges: null,
  selectedBar: null,
  activeTimelineProject: null,
  selectedRow: null,
};

export const meetingsScheduleSlice = createSlice({
  name: 'meetingsSchedule',
  initialState,
  reducers: {
    setOpenOpModeModal(state) {
      state.operatingModeModal = true;
    },
    setCloseOpModeModal(state) {
      state.operatingModeModal = false;
    },
    setOpenCreateEventDialog(state) {
      state.createEventDialog = true;
    },
    setCloseCreateEventDialog(state) {
      state.createEventDialog = false;
    },
    setSelectedRanges(state, action) {
      state.selectedRanges = action.payload;
    },
    setSelectedBar(state, action) {
      state.selectedBar = action.payload;
    },
    setActiveTimelineProject(state, action) {
      state.activeTimelineProject = action.payload;
    },
    setSelectedRow(state, action) {
      state.selectedRow = action.payload;
    },
  },
});

export const meetingsScheduleApi = createApi({
  reducerPath: 'meetingsScheduleApi',
  baseQuery: fetchBaseQuery({
    baseUrl: DOMAIN_RELATIVE,
    credentials: 'include',
  }),
  endpoints: builder => ({
    getProjectMeetings: builder.query({
      query: ({ projectId, date }) => `${apiRoutes.meetingsSchedule}?projectId=${projectId}&date=${date}`,
      transformResponse: response => {
        const managers = meetingsScheduleAdapter.adaptManagerList(response.managers);
        const events = meetingsScheduleAdapter.adaptMeetingsSlots(response.events);
        return { managers, events };
      },
    }),
    getOperatingMode: builder.query({
      // query: scheduleId => `${apiRoutes.operatingMode}/${scheduleId}`,
      queryFn: () => ({
        data: mockOperatingMode,
      }),
    }),
    createOperatingMode: builder.mutation({
      query: data => ({
        url: apiRoutes.operatingMode,
        method: 'POST',
        body: data,
      }),
    }),
    getVacationsManager: builder.query({
      queryFn: () => ({
        data: mockVacationsManager,
      }),
    }),
    createVacationManager: builder.mutation({
      query: data => ({
        url: apiRoutes.vacationsManager,
        method: 'POST',
        body: data,
      }),
    }),
    createMeeting: builder.mutation({
      query: data => ({
        url: apiRoutes.createMeeting,
        method: 'POST',
        body: data,
      }),
    }),
    getFreeRooms: builder.query({
      query: ({ projectId, meetingStart, meetingEnd }) =>
        `${apiRoutes.freeRooms}?projectId=${projectId}&startDate=${meetingStart}&endDate=${meetingEnd}`,
    }),
    getMeetingById: builder.query({
      query: meetingId => `${apiRoutes.meetingById}/${meetingId}`,
    }),
    cancelMeeting: builder.mutation({
      query: ({ data, meetingId }) => ({
        url: `${apiRoutes.cancelMeeting}/${meetingId}`,
        method: 'PATCH',
        body: data,
      }),
    }),
    transferMeeting: builder.mutation({
      query: data => ({
        url: `${apiRoutes.transferMeeting}`,
        method: 'POST',
        body: data,
      }),
    }),
    getFreeManagers: builder.query({
      query: ({ projectId, meetingStart, meetingEnd }) =>
        `${apiRoutes.freeManagers}?projectId=${projectId}&startDate=${meetingStart}&endDate=${meetingEnd}`,
    }),
    createBriefing: builder.mutation({
      query: data => ({
        url: apiRoutes.createBriefing,
        method: 'POST',
        body: data,
      }),
    }),
    getBriefingById: builder.query({
      query: briefingId => `${apiRoutes.briefing}/${briefingId}`,
    }),
    cancelBriefing: builder.mutation({
      query: briefingId => ({
        url: `${apiRoutes.briefing}/${briefingId}`,
        method: 'DELETE',
      }),
    }),
    transferBriefing: builder.mutation({
      query: ({ data, briefingId }) => ({
        url: `${apiRoutes.briefing}/${briefingId}`,
        method: 'POST',
        body: data,
      }),
    }),
    createLunch: builder.mutation({
      query: data => ({
        url: `${apiRoutes.lunch}`,
        method: 'POST',
        body: data,
      }),
    }),
  }),
});

export const selectOpModeModalStatus = state => state.meetingsScheduleSlice.operatingModeModal;
export const selectCreateEventDialogStatus = state => state.meetingsScheduleSlice.createEventDialog;
export const selectSelectedRanges = state => state.meetingsScheduleSlice.selectedRanges;
export const selectSelectedBar = state => state.meetingsScheduleSlice.selectedBar;
export const selectActiveTimelineProject = state => state.meetingsScheduleSlice.activeTimelineProject;
export const selectSelectedRow = state => state.meetingsScheduleSlice.selectedRow;

export const {
  setOpenOpModeModal,
  setCloseOpModeModal,
  setOpenCreateEventDialog,
  setCloseCreateEventDialog,
  setSelectedRanges,
  setSelectedBar,
  setActiveTimelineProject,
  setSelectedRow,
} = meetingsScheduleSlice.actions;

export const {
  useGetProjectMeetingsQuery,
  useGetOperatingModeQuery,
  useCreateOperatingModeMutation,
  useGetVacationsManagerQuery,
  useCreateVacationManagerMutation,
  useCreateMeetingMutation,
  useGetFreeRoomsQuery,
  useGetMeetingByIdQuery,
  useGetFreeManagersQuery,
  useCreateBriefingMutation,
  useCreateLunchMutation,
} = meetingsScheduleApi;

export default meetingsScheduleSlice.reducer;
