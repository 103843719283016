import { useState, useEffect } from 'react';
import { Card, Typography, Box, InputAdornment, Stack, styled } from '@mui/material';
import style from './Calculator.module.scss';
import { Colors } from '../../assets/mui-styles/Colors';
import { CustomSelect } from './ui/CustomSelect';
import { CustomInput } from './ui/CustomInput';
import { CustomInputWithSlider } from './ui/CustomInputWithSlider';
import { CustomSwitch } from './ui/CustomSwitch';
import {
  NumberSumFormatCustom,
  PercentFormatCustom,
  NumberSumFormatDefault,
} from './ui/InputComponentsForInputWithSlider';
import { EarlyPaymentCard } from './ui/EarlyPaymentCard';
import { toFormat, toFormatDefault } from '../../utils/utils';
import { calculatorFreeTabNew } from '../../utils/feature-flags';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { checkIfCalculatorByFreeNewPropsValid } from '../../utils/calculatorUtils';

export const CalculatorForFree = ({
  benefits = [],
  currentBenefit,
  handleChangeBenefit,
  flatPrice,
  handleChangeFlatPrice,
  isPercent,
  firstPayment,
  handleChangeFirstPayment,
  handleChangeFirstPaymentCommitted,
  minFlatPrice,
  maxFlatPrice,
  fetchStatus,
  minFirstPayment,
  maxFirstPayment,
  term,
  handleChangeTerm,
  handleChangeTermCommitted,
  minTerm,
  maxTerm,
  handleSwitchValue,
  percentFirstPayment,
  handleChangePercentValue,
  priceWithMargin,
  flatPriceNew,
  minFlatPriceNew,
  maxFlatPriceNew,
  handleChangeFlatPriceNew,
  handleChangeTermNew,
  termForDisplayNew,
  minTermNew,
  maxTermNew,
  rateForDisplayNew,
  minRateNew,
  maxRateNew,
  handleChangeRateNew,
  isPercentNew,
  handleSwitchFirstPaymentType,
  firstPaymentPercentDisplayNew,
  firstPaymentCurrencyDisplayNew,
  handleChangeFirstPaymentNew,
  minFirstPaymentPercentNew,
  maxFirstPaymentPercentNew,
  minFirstPaymentCurrencyNew,
  maxFirstPaymentCurrencyNew,
  earlyPaymentOptions,
  earlyPaymentsForDisplay,
  onChangeEarlyPayment,
  createEmptyPayment,
  deletePayment,
  totalCreditMonthsFree,
  freeYearlyPayments,
  freeCreditSum,
  freeMonthlyPayment,
  firstPaymentCurrencyNewError,
  firstPaymentPercentNewError,
  flatPriceNewError,
  termNewError,
  rateError,
  earlyPaymentsType,
  onChangeEarlyPaymentsType,
  handlePaymentError,
  earlyPaymentsErrors,
  rateNew,
}) => {
  const [errorFlatPrice, setErrorFlatPrice] = useState('');
  const [errorFirstPayment, setErrorFirstPayment] = useState('');
  const [errorTerm, setErrorTerm] = useState('');

  // логика для первой версии калькулятора
  useEffect(() => {
    if (maxFlatPrice < minFlatPrice || Number(flatPrice) === 0) {
      setErrorFlatPrice('Увеличьте стоимость квартиры');
    } else {
      setErrorFlatPrice('');
    }
  }, [maxFlatPrice, minFlatPrice, flatPrice]);

  useEffect(() => {
    if (firstPayment < minFirstPayment) {
      setErrorFirstPayment('Увеличьте сумму первого взноса');
    } else if (firstPayment > maxFirstPayment) {
      setErrorFirstPayment('Уменьшите сумму первого взноса');
    } else {
      setErrorFirstPayment('');
    }
  }, [firstPayment, maxFirstPayment, minFirstPayment]);

  useEffect(() => {
    if (term < minTerm) {
      setErrorTerm('Увеличьте срок кредита');
    } else if (term > maxTerm) {
      setErrorTerm('Уменьшите срок кредита');
    } else {
      setErrorTerm('');
    }
  }, [maxTerm, minTerm, term]);

  return (
    <>
      <StyledCard className={style.calculatorBlockContainer}>
        <Typography variant='h3' fontSize='28px' fontWeight='500'>
          Рассчитать платеж
        </Typography>
        {calculatorFreeTabNew ? (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: '30px',
            }}
          >
            <Box width='40%'>
              <CustomInput
                disabled={fetchStatus === 'pending'}
                placeholder='20 000 000'
                helperText={flatPriceNewError}
                error={flatPriceNewError !== ''}
                valueInput={flatPriceNew ? toFormat(flatPriceNew) : ''}
                endAdornment={<InputAdornment position='end'>₽</InputAdornment>}
                label='Стоимость объекта недвижимости'
                inputComponent={NumberSumFormatDefault}
                onChangeInput={value => handleChangeFlatPriceNew(value)}
                fetchStatus={fetchStatus}
              />
            </Box>
            {flatPriceNew >= minFlatPriceNew && flatPriceNew <= maxFlatPriceNew && (
              <>
                <Box
                  sx={{
                    display: 'flex',
                    gap: '20px',
                  }}
                >
                  <Box sx={{ width: '40%', display: 'flex', flexDirection: 'column' }}>
                    <CustomInputWithSlider
                      fetchStatus={fetchStatus}
                      helperText={isPercentNew ? firstPaymentPercentNewError : firstPaymentCurrencyNewError}
                      error={isPercentNew ? firstPaymentPercentNewError !== '' : firstPaymentCurrencyNewError !== ''}
                      onChangeInput={value => {
                        if (Number.isNaN(value)) return;
                        handleChangeFirstPaymentNew(value);
                      }}
                      onChangeSlider={handleChangeFirstPaymentNew}
                      onChange={value => handleChangeFirstPaymentNew(value)}
                      valueSlider={isPercentNew ? firstPaymentPercentDisplayNew : firstPaymentCurrencyDisplayNew}
                      value={isPercentNew ? firstPaymentPercentDisplayNew : firstPaymentCurrencyDisplayNew}
                      valueInput={
                        isPercentNew ? firstPaymentPercentDisplayNew : toFormatDefault(firstPaymentCurrencyDisplayNew)
                      }
                      label='Сумма первоначального взноса'
                      min={isPercentNew ? minFirstPaymentPercentNew : minFirstPaymentCurrencyNew}
                      max={isPercentNew ? maxFirstPaymentPercentNew : maxFirstPaymentCurrencyNew}
                      minValueText={
                        isPercentNew ? `${minFirstPaymentPercentNew}%` : toFormat(minFirstPaymentCurrencyNew)
                      }
                      maxValueText={
                        isPercentNew ? `${maxFirstPaymentPercentNew}%` : toFormat(maxFirstPaymentCurrencyNew)
                      }
                      endAdornment={
                        <InputAdornment position='end'>
                          {isPercentNew
                            ? toFormat(firstPaymentCurrencyDisplayNew)
                            : `${firstPaymentPercentDisplayNew} %`}
                        </InputAdornment>
                      }
                      onChangeCommitted={handleChangeFirstPaymentNew}
                    />
                    <CustomSwitch
                      onClick={handleSwitchFirstPaymentType}
                      checked={isPercentNew}
                      label='Сумма первого взноса, %'
                      sx={{
                        width: 'max-content',
                        position: 'relative',
                        right: '10px',
                      }}
                    />
                  </Box>
                  <Box width='24%'>
                    <CustomInputWithSlider
                      disabled={fetchStatus === 'pending'}
                      helperText={termNewError}
                      error={termNewError !== ''}
                      onChange={e => handleChangeTermNew(e)}
                      onChangeInput={e => {
                        // не даем ввести больше 2 символов
                        if (Array.from(String(e))?.length <= 2) {
                          handleChangeTermNew(e);
                        }
                      }}
                      value={termForDisplayNew}
                      label='Срок кредита'
                      min={minTermNew}
                      max={maxTermNew}
                      minValueText={minTermNew}
                      maxValueText={maxTermNew}
                      inputType='number'
                      endAdornment={<InputAdornment position='end'>лет</InputAdornment>}
                      fetchStatus={fetchStatus}
                    />
                  </Box>
                  <Box width='32%'>
                    <CustomInputWithSlider
                      disabled={fetchStatus === 'pending'}
                      helperText={rateError}
                      error={rateError !== ''}
                      onChange={handleChangeRateNew}
                      onChangeInput={e => {
                        // не даем ввести больше 3 символов
                        if (Array.from(String(e))?.length <= 3) {
                          handleChangeRateNew(e);
                        }
                      }}
                      value={rateForDisplayNew}
                      label='Процентная ставка'
                      min={minRateNew}
                      max={maxRateNew}
                      minValueText={minRateNew}
                      maxValueText={maxRateNew}
                      inputType='number'
                      endAdornment={<InputAdornment position='end'>%</InputAdornment>}
                      fetchStatus={fetchStatus}
                    />
                  </Box>
                </Box>
              </>
            )}
          </Box>
        ) : (
          <Stack
            sx={{
              display: 'grid',
              gap: '24px',
              gridTemplateColumns: 'minmax(0, 1fr) 13vw',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: '24px',
              }}
            >
              {benefits?.length > 0 && (
                <CustomSelect
                  label='Выберите программу'
                  handleChangeCurrentItem={value => {
                    handleChangeBenefit(value);
                  }}
                  currentItem={currentBenefit}
                  items={benefits}
                />
              )}
              {currentBenefit && flatPrice && errorFlatPrice === '' ? (
                <CustomInputWithSlider
                  helperText={errorFirstPayment}
                  error={firstPayment < minFirstPayment || firstPayment > maxFirstPayment}
                  onChangeInput={isPercent ? handleChangePercentValue : handleChangeFirstPaymentCommitted}
                  onChangeSlider={handleChangeFirstPayment}
                  valueSlider={firstPayment}
                  valueInput={isPercent ? percentFirstPayment : firstPayment}
                  label='Сумма первоначального взноса'
                  min={minFirstPayment}
                  max={maxFirstPayment}
                  minValueText={
                    isPercent
                      ? `${Math.round(((minFirstPayment * 100) / priceWithMargin) * 10) / 10}%`
                      : toFormat(minFirstPayment)
                  }
                  maxValueText={
                    isPercent
                      ? `${Math.round(((maxFirstPayment * 100) / priceWithMargin) * 10) / 10}%`
                      : toFormat(maxFirstPayment)
                  }
                  inputComponent={isPercent ? PercentFormatCustom : NumberSumFormatCustom}
                  endAdornment={
                    <InputAdornment position='end'>
                      {isPercent ? toFormat(firstPayment) : `${percentFirstPayment} %`}
                    </InputAdornment>
                  }
                  onChangeCommitted={handleChangeFirstPaymentCommitted}
                />
              ) : null}
            </Box>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: '24px',
              }}
            >
              {benefits?.length > 0 && (
                <CustomInput
                  className={style.customInput}
                  helperText={errorFlatPrice}
                  error={maxFlatPrice < minFlatPrice || Number(flatPrice) === 0}
                  valueInput={flatPrice}
                  endAdornment={<InputAdornment position='end'>₽</InputAdornment>}
                  label='Стоимость квартиры'
                  inputComponent={NumberSumFormatDefault}
                  onChangeInput={handleChangeFlatPrice}
                  fetchStatus={fetchStatus}
                />
              )}

              {currentBenefit && flatPrice && errorFlatPrice === '' ? (
                <CustomInputWithSlider
                  helperText={errorTerm}
                  error={term < minTerm || term > maxTerm}
                  onChange={handleChangeTerm}
                  onChangeCommitted={handleChangeTermCommitted}
                  onChangeInput={handleChangeTermCommitted}
                  value={term}
                  label='Срок кредита'
                  min={minTerm}
                  max={maxTerm}
                  minValueText={minTerm}
                  maxValueText={maxTerm}
                  inputType='number'
                  fetchStatus={fetchStatus}
                />
              ) : null}
            </Box>
            {currentBenefit && flatPrice && errorFlatPrice === '' ? (
              <CustomSwitch
                onClick={handleSwitchValue}
                checked={isPercent}
                label='Сумма первого взноса, %'
                sx={{
                  width: 'max-content',
                  position: 'relative',
                  right: '10px',
                }}
              />
            ) : null}
          </Stack>
        )}
      </StyledCard>
      {calculatorFreeTabNew &&
        checkIfCalculatorByFreeNewPropsValid({
          propsForCalculatorByFreeNew: {
            freeCreditSum,
            flatPriceNew,
            minFlatPriceNew,
            maxFlatPriceNew,
            termNewError,
            rateError,
            flatPriceNewError,
            firstPaymentPercentNewError,
            firstPaymentCurrencyNewError,
            rateNew,
          },
        }) && (
          <StyledCard className={style.calculatorBlockContainer}>
            <Typography variant='h3' fontSize='24px' fontWeight='500'>
              Рассчитать досрочное погашение
            </Typography>
            <Box
              sx={{
                width: '30%',
              }}
            >
              <CustomSelect
                label='Что уменьшить?'
                handleChangeCurrentItem={onChangeEarlyPaymentsType}
                currentItem={{ value: earlyPaymentsType?.value, title: earlyPaymentsType?.title } || ''}
                items={earlyPaymentOptions}
              />
            </Box>
            {earlyPaymentsForDisplay?.map((payment, index) => {
              return (
                <EarlyPaymentCard
                  freeYearlyPayments={freeYearlyPayments}
                  key={payment?.id}
                  payment={payment}
                  index={index}
                  deletePayment={deletePayment}
                  onChangeEarlyPayment={onChangeEarlyPayment}
                  handlePaymentError={handlePaymentError}
                  earlyPaymentsErrors={earlyPaymentsErrors}
                  fetchStatus={fetchStatus}
                  totalCreditMonthsFree={totalCreditMonthsFree}
                />
              );
            })}
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: '8px',
                padding: '8px 16px 8px 12px',
                background: Colors.additional,
                width: 'fit-content',
                cursor: 'pointer',
              }}
              onClick={createEmptyPayment}
            >
              <AddCircleIcon
                sx={{
                  width: '20px',
                  height: '20px',
                }}
              />
              <Typography fontSize={14}>Добавить платеж</Typography>
            </Box>
          </StyledCard>
        )}
    </>
  );
};

const StyledCard = styled(Card)`
  display: flex;
  flex-direction: column;
  gap: 22px;
  background: ${Colors.additional};
  border: 1px solid;
  border-color: ${Colors.superLightGrey};
  border-top-color: transparent;
`;
