import dayjs from 'dayjs';
import { makeName } from '../utils/utils';

const setTitle = eventData => {
  if (eventData) {
    if (eventData.name) {
      return `${eventData.name} (${eventData.room})`;
    }

    if (eventData.title) {
      return eventData.title;
    }
  }

  return 'Без названия';
};

const meetingsScheduleAdapter = {
  adaptManagerList: managersData =>
    managersData.map(manager => ({
      id: manager.id,
      name: makeName(manager.lastName, manager.firstName, manager.patronymic),
    })),

  adaptMeetingsSlots: events =>
    events
      .filter(e => e.startTime)
      .map(e => {
        const endDate = dayjs(e.startTime).add(e.duration, 'm');

        return {
          graphicId: e.eventId,
          uniqId: `bar_${e.eventId}`,
          startDjs: e.startTime,
          endDjs: endDate.format(),
          type: e.type,
          title: setTitle(e.eventData),
          managerIds: e.managerIds,
          online: e.eventData?.isOnline || false,
          clientName: e.eventData?.name || '', // ?
          // clientCome: false, // ?
          // managerCome: true, // ?
          comment: '',
        };
      }),
};

export default meetingsScheduleAdapter;
