import { Box, Card, Stack, ThemeProvider, Typography } from '@mui/material';
import { styled } from '@mui/system';
import { Colors } from '../../../assets/mui-styles/Colors';
import { equalizerTheme } from '../../../assets/mui-styles/GlobalStyles';
import { EqualizerCompareButton } from '../../equalizer-page-new/equalizer-compare-button/EqualizerCompareButton';
import style from './EstimationBlock.module.scss';
import DOMPurify from 'dompurify';
import MortgageColumnBox from '../../../widgets/mortgage-column-box/MortgageColumnBox.jsx';
import { calculatorFreeTabNew } from '../../../utils/feature-flags.js';

export const EstimationBlock = ({
  flatPrice,
  currentBenefit,
  calcData,
  initialPayment,
  fetchStatus,
  flatsData,
  meetingId,
  termCommitted,
  flatData,
  isLotTab,
  isEqualizer,
  firstPaymentCommitted,
  benefits,
  priceWithMargin,
  isFreeTab = false,
  freeCreditSum,
  freeMonthlyPayment,
}) => {
  const monthlyPaymentSubsidy = calcData?.monthlyPaymentSubsidy;
  const monthlySubsidyExists = Boolean(monthlyPaymentSubsidy);
  const amountOfCredit =
    isLotTab && isEqualizer
      ? currentBenefit?.benefitPrice - currentBenefit?.mortgage?.firstPayment
      : calcData?.amountOfCredit || initialPayment?.amountOfCredit;
  const normalMonthlyPayment =
    isLotTab && isEqualizer ? currentBenefit?.mortgage?.minMonthFee : calcData?.monthlyPayment;
  const subsidyTerm = calcData?.subsidyTerm;
  const paymentTerm = subsidyTerm + 1 === 2 ? 'cо' : `c ${subsidyTerm + 1} года`;
  return (
    <Card
      className={style.estimationBlockContainer}
      sx={{
        background: Colors.additional,
        height: isFreeTab ? 'fit-content' : '100%',
      }}
    >
      {fetchStatus === 'pending' && <div className={style.whiteOverlay} />}

      <Stack
        direction='column'
        alignItems='start'
        justifyContent='start'
        spacing='24px'
        sx={{ mb: '5%' }}
        className={style.estimationBlockStack}
      >
        {currentBenefit && !isFreeTab && (
          <Stack
            direction='column'
            alignItems='start'
            justifyContent='start'
            spacing='24px'
            sx={{
              padding: '0 16px',
              width: '100%',
            }}
          >
            <Box sx={{ width: '100%' }}>
              <Typography
                sx={{
                  fontSize: '24px',
                  lineHeight: '44px',
                  fontWeight: '500',
                  letterSpacing: '-0.5px',
                }}
              >
                {currentBenefit?.name}
              </Typography>
            </Box>

            <Stack
              display='grid'
              gridTemplateColumns='repeat(auto-fit, minmax(100px, 1fr))'
              gap='24px'
              sx={{
                width: '100%',
              }}
            >
              <Box sx={{ width: '100%' }}>
                {currentBenefit.description && (
                  <Typography
                    sx={{
                      fontSize: 12,
                      lineHeight: '150%',
                    }}
                    dangerouslySetInnerHTML={{
                      __html: DOMPurify.sanitize(currentBenefit?.description),
                    }}
                  />
                )}

                {currentBenefit.minFirstPaymen && (
                  <p className={style.descParagraph}>ПВ от {currentBenefit.minFirstPaymen}%</p>
                )}

                {Boolean(currentBenefit.rate) && <p className={style.descParagraph}>Ставка {currentBenefit.rate}%</p>}

                {currentBenefit.maxYears && (
                  <p className={style.descParagraph}>Срок до {currentBenefit.maxYears} лет</p>
                )}

                {currentBenefit.maxSumCredit && (
                  <p className={style.descParagraph}>Сумма кредита до {currentBenefit.maxSumCredit / 1000000} млн.р</p>
                )}
              </Box>

              {flatPrice && (
                <Box sx={{ width: '100%' }} className={style.realPrice}>
                  <Typography className={style.estimaionItemPrice}>
                    {new Intl.NumberFormat('ru-RU').format(Number.parseInt(flatPrice, 10))} ₽
                  </Typography>
                  <p>Прайсовая цена</p>
                </Box>
              )}
            </Stack>
          </Stack>
        )}

        {(initialPayment?.priceWithMargin || priceWithMargin) && !isFreeTab && (
          <div className={style.estimationBlockInnerStack}>
            <Stack sx={{ gap: '20px' }}>
              <MortgageColumnBox
                title={'Стоимость квартиры по программе'}
                value={calcData?.priceWithMargin ?? initialPayment?.priceWithMargin ?? priceWithMargin}
              />
              {monthlySubsidyExists && (
                <MortgageColumnBox title={'Ежемесячный платеж на льготный период'} value={monthlyPaymentSubsidy} />
              )}
            </Stack>

            {(!(isLotTab && isEqualizer) || currentBenefit?.mortgage) && (
              <Stack sx={{ gap: '20px' }}>
                <MortgageColumnBox title='Сумма кредита' value={amountOfCredit} />
                <MortgageColumnBox
                  title={monthlySubsidyExists ? `Ежемесячный платеж ${paymentTerm}` : 'Ежемесячный платеж'}
                  value={normalMonthlyPayment}
                />
              </Stack>
            )}
          </div>
        )}

        {isLotTab && isEqualizer && (
          <ThemeProvider theme={equalizerTheme}>
            <Stack className={style.buttonStack}>
              {currentBenefit.mortgage !== null && currentBenefit.group === 'mortgage' && (
                <EqualizerCompareButton
                  meetingId={meetingId}
                  benefitId={currentBenefit?.id}
                  flats={flatsData}
                  flatData={flatData}
                  loanTerm={termCommitted}
                  firstPaymentCommitted={firstPaymentCommitted}
                  benefits={benefits}
                />
              )}
            </Stack>
          </ThemeProvider>
        )}
        {isFreeTab && !calculatorFreeTabNew && (
          <Stack
            sx={{
              width: '100%',
              display: 'grid',
              gridTemplateColumns: '1fr 1fr',
              gap: '40px',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: '40px',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  gap: '40px',
                }}
              >
                <StyledBoxFree>
                  <StyledTypographyFreeBold>
                    {`от ${currentBenefit?.minFirstPayment ? currentBenefit?.minFirstPayment : '-'}%`}
                  </StyledTypographyFreeBold>
                  <StyledTypographyFreeCaption>Первый взнос</StyledTypographyFreeCaption>
                </StyledBoxFree>
                <StyledBoxFree>
                  <StyledTypographyFreeBold>
                    {`от ${currentBenefit?.rate ? currentBenefit?.rate : ''}%`}
                  </StyledTypographyFreeBold>
                  <StyledTypographyFreeCaption>Ставка</StyledTypographyFreeCaption>
                </StyledBoxFree>
              </Box>
              <MortgageColumnBox title='Сумма кредита' value={amountOfCredit} />
            </Box>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: '40px',
              }}
            >
              <StyledBoxFree>
                <StyledTypographyFreeBold>
                  {' '}
                  {new Intl.NumberFormat('ru-RU').format(Number.parseInt(flatPrice, 10))} ₽
                </StyledTypographyFreeBold>
                <StyledTypographyFreeCaption>Прайсовая цена</StyledTypographyFreeCaption>
              </StyledBoxFree>
              <MortgageColumnBox title='Ежемесячный платеж' value={normalMonthlyPayment} />
            </Box>
          </Stack>
        )}
        {isFreeTab && calculatorFreeTabNew && (
          <Box width={'100%'}>
            <StyledTypographyFreeCalculatorNew>Предварительный расчет</StyledTypographyFreeCalculatorNew>
            <StyledBoxFreeNew>
              <MortgageColumnBox title='Сумма кредита' value={freeCreditSum} />
              <MortgageColumnBox title='Ежемесячный платеж' value={freeMonthlyPayment} />
            </StyledBoxFreeNew>
          </Box>
        )}
      </Stack>
    </Card>
  );
};

const StyledBoxFree = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  gap: '10px',
});

const StyledBoxFreeNew = styled(Box)({
  width: '100%',
  display: 'grid',
  gridTemplateColumns: '1fr 1fr',
  gap: '21px',
  marginTop: '20px',
});

const StyledTypographyFreeBold = styled(Typography)({
  fontSize: '28px',
  fontWeight: '500',
  lineHeight: '27px',
});

const StyledTypographyFreeCaption = styled(Typography)({
  fontSize: '18px',
  fontWeight: '400',
  lineHeight: '27px',
});

const StyledTypographyFreeCalculatorNew = styled(Typography)({
  fontSize: '28px',
  lineHeight: '44px',
  fontWeight: '500',
  letterSpacing: '-0.5px',
});
