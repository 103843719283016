import { useEffect, useState } from 'react';
import { fetchFreeBenefits } from './dataFetchers';

export const useGetAllBenefits = ({ handleSetPendingStatus, handleSetLoadedStatus, handleSetErrorStatus }) => {
  const [benefits, setBenefits] = useState([]);
  useEffect(() => {
    fetchFreeBenefits()
      .then(result => {
        handleSetPendingStatus();
        setBenefits(result);
      })
      .catch(() => handleSetErrorStatus())
      .finally(() => {
        handleSetLoadedStatus();
      });
  }, []);

  return {
    benefits,
  };
};
