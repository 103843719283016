import { Box, Button, Stack, Typography } from '@mui/material';
import { motion } from 'framer-motion';
import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  calcDataLotSelector,
  changeEstimateModalStatus,
  currBenefitLotSelector,
  currFlatSelector,
  fetchBenefitsStatusSelector,
  fetchStatusSelector,
  initialPaymentLotSelector,
  mortgagesPriceSelector,
  warningBenefitSelector,
} from '../../entities/ProjectSlice';
import { handleBodyLock, handleBodyUnlock, useOnClickOutside } from '../../utils/utils';
import ErrorBlock from '../error-block/ErrorBlock';
import MortgageColumn from '../mortgage-column/MortgageColumn';
import style from './ModalEstimation.module.scss';
import DOMPurify from 'dompurify';

const animationPropsA = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
  },
  exit: {
    opacity: 0,
  },
};

const animationPropsB = {
  hidden: {
    bottom: '-1000px',
  },
  visible: {
    bottom: '0px',
  },
  exit: {
    bottom: '-1000px',
  },
};

function ModalEstimation() {
  handleBodyLock();

  const dispatch = useDispatch();
  const currFlat = useSelector(currFlatSelector());
  const fetchStatus = useSelector(fetchStatusSelector());
  const fetchBenefitsStatus = useSelector(fetchBenefitsStatusSelector());
  const warningBenefit = useSelector(warningBenefitSelector());
  const mortgagePrice = useSelector(mortgagesPriceSelector());
  const currBenefitLot = useSelector(currBenefitLotSelector());
  const initialPaymentLot = useSelector(initialPaymentLotSelector());
  const calcDataLot = useSelector(calcDataLotSelector());
  const currBenefit = mortgagePrice.find(i => i.id === currBenefitLot);

  const ref = useRef();

  useOnClickOutside(ref, () => handleModalClose());

  const handleModalClose = () => {
    dispatch(changeEstimateModalStatus(false));
    handleBodyUnlock();
  };

  useEffect(() => {
    if (warningBenefit === true) {
      handleModalClose();
    } else return;
  }, [warningBenefit]);

  return (
    <motion.div
      className={style.container}
      variants={animationPropsA}
      initial='hidden'
      animate='visible'
      exit='hidden'
      transition={{ duration: 0.5, type: 'tween' }}
    >
      <motion.div
        className={style.card}
        // className={!styleStatus ? style.card : style.cardAlt}
        // style={{ height: elementHeight }}
        variants={animationPropsB}
        initial='hidden'
        animate='visible'
        exit='hidden'
        ref={ref}
        id='targetCard'
        transition={{ duration: 0.5, type: 'tween' }}
      >
        {fetchStatus === 'error' && <ErrorBlock />}

        <Stack className={style.estimationBlockStack}>
          {fetchBenefitsStatus === 'pending' && <div className={style.whiteOverlay} />}

          {initialPaymentLot?.priceWithMargin && (
            <>
              <Stack
              // sx={{ padding: "0 4px" }}
              >
                <Box sx={{ width: '100%' }}>
                  <Typography
                    component='div'
                    variant='h2'
                    sx={{
                      fontSize: 24,
                      mb: '16px',
                    }}
                  >
                    {currBenefit?.name}
                  </Typography>
                </Box>

                <Box sx={{ width: '100%' }}>
                  {currBenefit.description && (
                    <Typography
                      component='div'
                      variant='div'
                      sx={{
                        fontSize: 12,
                        lineHeight: '150%',
                      }}
                      dangerouslySetInnerHTML={{
                        __html: DOMPurify.sanitize(currBenefit.description),
                      }}
                    />
                  )}

                  {currBenefit.minFirstPaymen && (
                    <p className={style.descParagraph}>ПВ от {currBenefit.minFirstPaymen}%</p>
                  )}

                  {currBenefit.rate && <p className={style.descParagraph}>Ставка {currBenefit.rate}%</p>}

                  {currBenefit.maxYears && <p className={style.descParagraph}>Срок до {currBenefit.maxYears} лет</p>}

                  {currBenefit.maxSumCredit && (
                    <p className={style.descParagraph}>Сумма кредита до {currBenefit.maxSumCredit / 1000000} млн.р</p>
                  )}
                </Box>

                {currFlat?.real_price && (
                  <Box sx={{ width: '100%' }} className={style.realPrice}>
                    <Typography component='div' variant='div'>
                      {new Intl.NumberFormat('ru-RU').format(Number.parseInt(currFlat.real_price))} ₽
                    </Typography>
                    <p>Прайсовая цена</p>
                  </Box>
                )}
              </Stack>
            </>
          )}

          {initialPaymentLot?.priceWithMargin && (
            <div className={style.estimationBlockInnerStack}>
              <Box sx={{ width: '100%' }} className={style.estimationItem}>
                <Typography component='div' variant='h6' className={style.estimaionItemHeading}>
                  Стоимость квартиры по программе
                </Typography>
                <Typography className={style.estimaionItemSum}>
                  {new Intl.NumberFormat('ru-RU').format(
                    Number.parseInt(calcDataLot.priceWithMargin ?? initialPaymentLot.priceWithMargin),
                  )}{' '}
                  ₽
                </Typography>
              </Box>

              <MortgageColumn />

              {/*{currBenefit.installment === true && (*/}
              {/*	<>*/}
              {/*		{currBenefit.installmentPayment.map((i) => {*/}
              {/*			return (*/}
              {/*				<Box*/}
              {/*					sx={{ width: "100%" }}*/}
              {/*					className={style.estimationItem}*/}
              {/*				>*/}
              {/*					<Typography*/}
              {/*						component="div"*/}
              {/*						variant="h6"*/}
              {/*						className={style.estimaionItemHeading}*/}
              {/*					>*/}
              {/*						{i.description}*/}
              {/*					</Typography>*/}
              {/*					<Typography className={style.estimaionItemSum}>*/}
              {/*						{new Intl.NumberFormat("ru-RU").format(*/}
              {/*							parseInt(currBenefit.benefitPrice * (+i.percent / 100))*/}
              {/*						)}{" "}*/}
              {/*						₽*/}
              {/*					</Typography>*/}
              {/*				</Box>*/}
              {/*			)*/}
              {/*		})}*/}
              {/*	</>*/}
              {/*)}*/}

              {/*{currBenefit.group === "mortgage" && currBenefit.id === 134616 && (*/}
              {/*	<>*/}
              {/*		<Box*/}
              {/*			sx={{ width: "100%" }}*/}
              {/*			className={style.estimationItem}*/}
              {/*		>*/}
              {/*			<Typography*/}
              {/*				component="div"*/}
              {/*				variant="h6"*/}
              {/*				className={style.estimaionItemHeading}*/}
              {/*			>*/}
              {/*				Первый платёж - 15%*/}
              {/*			</Typography>*/}
              {/*			<Typography className={style.estimaionItemSum}>*/}
              {/*				{new Intl.NumberFormat("ru-RU").format(*/}
              {/*					parseInt(currBenefit.benefitPrice * 0.15)*/}
              {/*				)}{" "}*/}
              {/*				₽*/}
              {/*			</Typography>*/}
              {/*		</Box>*/}
              {/*		<Box*/}
              {/*			sx={{ width: "100%" }}*/}
              {/*			className={style.estimationItem}*/}
              {/*		>*/}
              {/*			<Typography*/}
              {/*				component="div"*/}
              {/*				variant="h6"*/}
              {/*				className={style.estimaionItemHeading}*/}
              {/*			>*/}
              {/*				Второй платёж - 10%*/}
              {/*			</Typography>*/}
              {/*			<Typography className={style.estimaionItemSum}>*/}
              {/*				{new Intl.NumberFormat("ru-RU").format(*/}
              {/*					parseInt(currBenefit.benefitPrice * 0.1)*/}
              {/*				)}{" "}*/}
              {/*				₽*/}
              {/*			</Typography>*/}
              {/*		</Box>*/}
              {/*		<Box*/}
              {/*			sx={{ width: "100%" }}*/}
              {/*			className={style.estimationItem}*/}
              {/*		>*/}
              {/*			<Typography*/}
              {/*				component="div"*/}
              {/*				variant="h6"*/}
              {/*				className={style.estimaionItemHeading}*/}
              {/*			>*/}
              {/*				Третий платёж - 75%*/}
              {/*			</Typography>*/}
              {/*			<Typography className={style.estimaionItemSum}>*/}
              {/*				{new Intl.NumberFormat("ru-RU").format(*/}
              {/*					parseInt(currBenefit.benefitPrice * 0.75)*/}
              {/*				)}{" "}*/}
              {/*				₽*/}
              {/*			</Typography>*/}
              {/*		</Box>*/}
              {/*	</>*/}
              {/*)}*/}

              {/*{currBenefit.group === "cash" && currBenefit.id === 134781 && (*/}
              {/*	<>*/}
              {/*		<Box*/}
              {/*			sx={{ width: "100%" }}*/}
              {/*			className={style.estimationItem}*/}
              {/*		>*/}
              {/*			<Typography*/}
              {/*				component="div"*/}
              {/*				variant="h6"*/}
              {/*				className={style.estimaionItemHeading}*/}
              {/*			>*/}
              {/*				Первый платёж - 10%*/}
              {/*			</Typography>*/}
              {/*			<Typography className={style.estimaionItemSum}>*/}
              {/*				{new Intl.NumberFormat("ru-RU").format(*/}
              {/*					parseInt(currBenefit.benefitPrice * 0.1)*/}
              {/*				)}{" "}*/}
              {/*				₽*/}
              {/*			</Typography>*/}
              {/*		</Box>*/}
              {/*		<Box*/}
              {/*			sx={{ width: "100%" }}*/}
              {/*			className={style.estimationItem}*/}
              {/*		>*/}
              {/*			<Typography*/}
              {/*				component="div"*/}
              {/*				variant="h6"*/}
              {/*				className={style.estimaionItemHeading}*/}
              {/*			>*/}
              {/*				Второй платёж - 90%*/}
              {/*			</Typography>*/}
              {/*			<Typography className={style.estimaionItemSum}>*/}
              {/*				{new Intl.NumberFormat("ru-RU").format(*/}
              {/*					parseInt(currBenefit.benefitPrice * 0.9)*/}
              {/*				)}{" "}*/}
              {/*				₽*/}
              {/*			</Typography>*/}
              {/*		</Box>*/}
              {/*	</>*/}
              {/*)}*/}
            </div>
          )}

          <Button className={style.button} color='secondary' variant='contained' onClick={handleModalClose}>
            поменять условия
          </Button>
        </Stack>
      </motion.div>
    </motion.div>
  );
}

export default ModalEstimation;
