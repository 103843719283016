import { EstimationBlock } from '../../estimation-block/EstimationBlock';
import { Box } from '@mui/system';
import cn from 'classnames';
import style from '../CalculatorBlock.module.scss';

export const LotEstimationBlock = ({ propsForCalculatorByLot, fetchStatus, meetingId, isEqualizer, activeTab }) => {
  const checkIfDisplayLotEstimationBlock = () => {
    const {
      priceWithMargin,
      minFirstPayment,
      maxFirstPayment,
      benefit,
      term,
      maxTerm,
      minTerm,
      firstPaymentCommitted,
    } = propsForCalculatorByLot;

    const doesBenefitExist = Boolean(benefit);
    const isTermInRange = term >= minTerm && term <= maxTerm;
    const isPaymentInRange = firstPaymentCommitted <= maxFirstPayment && firstPaymentCommitted >= minFirstPayment;
    const isPriceWithMarginPositive = priceWithMargin > 0;
    return doesBenefitExist && isTermInRange && isPaymentInRange && isPriceWithMarginPositive;
  };

  const shouldDisplay = checkIfDisplayLotEstimationBlock();
  return (
    shouldDisplay &&
    ((isEqualizer && !propsForCalculatorByLot.isDefaultCalculation) || !isEqualizer) && (
      <Box sx={{ height: '100%' }} className={cn({ [style.wrapperStealth]: activeTab !== 'lot' })}>
        <EstimationBlock
          isEqualizer={isEqualizer}
          {...propsForCalculatorByLot}
          fetchStatus={fetchStatus}
          meetingId={meetingId}
          isLotTab
        />
      </Box>
    )
  );
};
