import { useState, useEffect } from 'react';
import { useDebouncedFunction } from '../../../hooks/useDebouncedFunction';
import { toFormat } from '../../../utils/utils';

export const useSelectFirstPaymentNew = ({ flatPrice, isPercent, uniqueId }) => {
  const sessionCurrencyName = `firstPayemntCurrency${uniqueId}`;
  // цена в процентах
  // стейт для визуального отображения
  const [firstPaymentPercentDisplayNew, setFirstPaymentPercentDisplayNew] = useState(1);
  // стейт для запроса на бэк
  const [firstPaymentPercentNew, setFirstPaymentPercentNew] = useState(1);
  const minFirstPaymentPercentNew = 1;
  const maxFirstPaymentPercentNew = 99;
  // цена в рублях
  // стейт для визуального отображения
  const [firstPaymentCurrencyDisplayNew, setFirstPaymentCurrencyDisplayNew] = useState(0);
  // стейт для запроса на бэк
  const [firstPaymentCurrencyNew, setFirstPaymentCurrencyNew] = useState(1);
  const [minFirstPaymentCurrencyNew, setMinFirstPaymentCurrencyNew] = useState(0);
  const [maxFirstPaymentCurrencyNew, setMaxFirstPaymentCurrencyNew] = useState(0);
  // ошибки
  const [firstPaymentCurrencyNewError, setFirstPaymentCurrencyNewError] = useState('');
  const [firstPaymentPercentNewError, setFirstPaymentPercentNewError] = useState('');

  const setFirstPaymentPercentDebounced = useDebouncedFunction(setFirstPaymentPercentNew, 400);
  const setFirstPaymentCurrencyDebounced = useDebouncedFunction(setFirstPaymentCurrencyNew, 400);

  const handleChangeFirstPaymentNew = value => {
    if (isPercent) {
      if (value < 0 || Number.isNaN(value)) {
        setFirstPaymentPercentDisplayNew(value);
        setFirstPaymentPercentDebounced(value);
        setFirstPaymentCurrencyDisplayNew(0);
        setFirstPaymentCurrencyDebounced(0);
        sessionStorage.setItem(sessionCurrencyName, 0);
        return;
      }

      setFirstPaymentPercentDisplayNew(value);
      setFirstPaymentPercentDebounced(value);
      const sum = parseFloat(((value / 100) * flatPrice).toFixed(2));
      setFirstPaymentCurrencyDisplayNew(sum);
      setFirstPaymentCurrencyDebounced(sum);
      sessionStorage.setItem(sessionCurrencyName, sum);
    } else {
      if (value < 0 || Number.isNaN(value)) {
        setFirstPaymentCurrencyDisplayNew(value);
        setFirstPaymentCurrencyDebounced(value);
        sessionStorage.setItem(sessionCurrencyName, value);
        setFirstPaymentPercentDisplayNew(0);
        setFirstPaymentPercentDebounced(0);
        return;
      }
      setFirstPaymentCurrencyDisplayNew(value);
      setFirstPaymentCurrencyDebounced(value);
      sessionStorage.setItem(sessionCurrencyName, value);
      const percent = parseFloat(((value / flatPrice) * 100).toFixed(2));
      setFirstPaymentPercentDisplayNew(percent);
      setFirstPaymentPercentDebounced(percent);
    }
  };

  useEffect(() => {
    const minCurrencyPayment = flatPrice / 100;
    const maxCurrencyPayment = flatPrice * 0.99;
    setMinFirstPaymentCurrencyNew(minCurrencyPayment);
    setMaxFirstPaymentCurrencyNew(maxCurrencyPayment);

    if (firstPaymentCurrencyDisplayNew < minCurrencyPayment) {
      setFirstPaymentCurrencyDisplayNew(minCurrencyPayment);
      setFirstPaymentCurrencyDebounced(minCurrencyPayment);
    }

    if (firstPaymentCurrencyDisplayNew > maxCurrencyPayment) {
      setFirstPaymentCurrencyDisplayNew(minCurrencyPayment);
      setFirstPaymentCurrencyDebounced(minCurrencyPayment);
    }
  }, [flatPrice]);

  // следим за изменением значений, добавляем ошибки
  // для цены в рублях
  useEffect(() => {
    if (firstPaymentCurrencyNew < minFirstPaymentCurrencyNew) {
      return setFirstPaymentCurrencyNewError(
        `Минимальная сумма первого взноса ${toFormat(minFirstPaymentCurrencyNew)}`,
      );
    } else if (firstPaymentCurrencyNew > maxFirstPaymentCurrencyNew) {
      return setFirstPaymentCurrencyNewError(
        `Максимальная сумма первого взноса ${toFormat(maxFirstPaymentCurrencyNew)}`,
      );
    } else {
      return setFirstPaymentCurrencyNewError('');
    }
  }, [firstPaymentCurrencyNew, minFirstPaymentCurrencyNew, maxFirstPaymentCurrencyNew]);

  // для цены в процентах

  useEffect(() => {
    if (firstPaymentPercentDisplayNew < minFirstPaymentPercentNew) {
      return setFirstPaymentPercentNewError(`Минимальный процент для первого взноса - ${minFirstPaymentPercentNew}%`);
    } else if (firstPaymentPercentDisplayNew > maxFirstPaymentPercentNew) {
      return setFirstPaymentPercentNewError(`Максимальный процент для первого взноса - ${maxFirstPaymentPercentNew}%`);
    } else {
      return setFirstPaymentPercentNewError('');
    }
  }, [firstPaymentPercentDisplayNew]);

  useEffect(() => {
    const storageCurrencyValue = sessionStorage.getItem(sessionCurrencyName);
    const storageFlatPriceValue = sessionStorage.getItem(`flatPrice${uniqueId}`);

    if (storageCurrencyValue && storageFlatPriceValue) {
      setFirstPaymentCurrencyNew(Number(storageCurrencyValue));
      setFirstPaymentCurrencyDisplayNew(Number(storageCurrencyValue));
      setFirstPaymentPercentNew(
        parseFloat(((Number(storageCurrencyValue) / Number(storageFlatPriceValue)) * 100).toFixed(2)),
      );
      setFirstPaymentPercentDisplayNew(
        parseFloat(((Number(storageCurrencyValue) / Number(storageFlatPriceValue)) * 100).toFixed(2)),
      );
    }
  }, []);

  return {
    firstPaymentPercentDisplayNew,
    firstPaymentCurrencyDisplayNew,
    firstPaymentPercentNew,
    firstPaymentCurrencyNew,
    handleChangeFirstPaymentNew,
    minFirstPaymentPercentNew,
    maxFirstPaymentPercentNew,
    minFirstPaymentCurrencyNew,
    maxFirstPaymentCurrencyNew,
    firstPaymentCurrencyNewError,
    firstPaymentPercentNewError,
  };
};
