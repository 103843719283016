import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { BlockIds, DOMAIN_RELATIVE } from '../../utils/constants.js';

export const blockId = `blockId=${BlockIds.REPUBLIC_BLOCK_ID}`;

export const apiRoutes = {
  meetingApi: 'api/meetings/meeting/',
  meetingsMonitoringApi: 'api/meetings/filterMeetings',
  rolesMonitoringApi: 'api/meetings/roles-managers',
  managersMonitoringApi: 'api/meetings/get-managers',
  presentationApi: 'api/presentation/',
  meetingsListApi: '/api/meetings/upcoming',
  comparedFlatsNew: '/api/meetings/:meetingId/calculation/list',
  compareDeleteNew: '/api/meetings/calculation/:benefitId',
  favoriteFlats: '/api/meetings/:meetingId/favorites',
  favoriteFlat: '/api/meetings/:meetingId/favorites/:flatId',
  bookedFlats: '/api/meetings/:meetingId/booked/list',
  bookedFlat: '/api/meetings/:meetingId/booked/:flatId',
  bookFlat: '/api/meetings/:meetingId/booked/add',
  officeList: '/api/meetings/rooms',
  projectsList: '/api/meetings/projects',
  fakeMeeting: '/api/meetings/update-fake/:meetingId',
  projectsListDetailed: 'https://manager.forma.ru/api/v2/block/',
  generateClientBookingLink: '/api/booked-link',
  secondaryMeetingsForMortgageManager: '/api/meetings/mortgage/',
  urlCatalog: `https://manager.forma.ru/api/v2/flat?${blockId}`,
  urlFlatCheck: 'https://manager.forma.ru/api/v2/flat/check?ids=',
  urlLotAtributes: '/api/lot-attributes/list',

  //Избранное сравнение
  favoritesComparedFlat: '/api/meetings/calculation/:meetingId',
  changeFavoritesComparedFlat: '/api/meetings/calculation/:meetingId/:comparedId',

  // график встреч
  meetingsSchedule: '/api/meetings-shedule/meetings',
  operatingMode: '/api/meetings-shedule/schedule',
  lunchSchedule: '/api/meetings-shedule/lunch',
  vacationsManager: '/api/meetings-shedule/vacation',
  createMeeting: '/api/meetings-shedule/meeting',
  freeRooms: '/api/meetings/free-meeting-rooms',
  transferMeeting: '/api/meetings-shedule/transfer-meeting-manager',
  cancelMeeting: '/api/meetings-shedule/cancel-meeting',
  meetingById: '/api/meetings-shedule/get-meeting',
  freeManagers: '/api/meetings-shedule/free-managers',
  createBriefing: '/api/meetings-shedule/briefing',
  briefing: '/api/meetings-shedule/briefing',
  lunch: '/api/meetings-shedule/lunch',
};

export const fetchCatalog = createAsyncThunk('flatsSlice/fetchFlats', async (url, thunkApi) => {
  try {
    const response = await axios.get(url);
    return response.data;
  } catch (error) {
    return onApiError(thunkApi, error);
  }
});
//Для заполенния атрибутов в избранном
export const fetchAttributesList = createAsyncThunk('flatsSlice/fetchFlatsAtributs', async thunkApi => {
  try {
    const response = await axios.get(`${DOMAIN_RELATIVE}${apiRoutes.urlLotAtributes}`);
    return response.data;
  } catch (error) {
    return onApiError(thunkApi, error);
  }
});

//Запрос для слайса catalogWorkplaceSlice
export const fetchCatalogWorkplace = createAsyncThunk(
  'flatsWorkplaceSlice/fetchWorkplaceFlats',
  async (url, thunkApi) => {
    try {
      const response = await axios.get(url);
      return response.data;
    } catch (error) {
      return onApiError(thunkApi, error);
    }
  },
);
//Для заполенния атрибутов в избранном
export const fetchAttributesListWorkplace = createAsyncThunk('flatsSlice/fetchFlatsAtributsWork', async thunkApi => {
  try {
    const response = await axios.get(`${DOMAIN_RELATIVE}${apiRoutes.urlLotAtributes}`);
    return response.data;
  } catch (error) {
    return onApiError(thunkApi, error);
  }
});

export const flatCheck = createAsyncThunk('favoriteSlice/fetchFlatsStatus', async (flatsIds, thunkApi) => {
  try {
    const response = await axios.get(`${apiRoutes.urlFlatCheck + flatsIds}&${blockId}`);
    return response.data;
  } catch (error) {
    return onApiError(thunkApi, error);
  }
});

export const fetchResidentialWithFloors = createAsyncThunk('flatsSlice/fetchWithFloors', async (blockId, thunkApi) => {
  try {
    const response = await axios.get(`${DOMAIN_RELATIVE}/api/v2/residential-with-floors?blockId=${blockId}`);
    return response.data;
  } catch (error) {
    return onApiError(thunkApi, error);
  }
});

export const fetchProjectsByRole = createAsyncThunk('projectsInfo/fetchProjectsByRole', async (role, thunkApi) => {
  try {
    const params = new URLSearchParams({ role });
    const requestByRole = axios.get(`${DOMAIN_RELATIVE}/api/projects/projectsByRole?${params}`, {
      withCredentials: true,
    });
    //Сделал двойной запрос для получения objectType для проектов по ролям.
    const requestListDetailed = axios.get(apiRoutes.projectsListDetailed, {
      method: 'GET',
    });

    return Promise.allSettled([requestByRole, requestListDetailed]).then(results => {
      const responseObject = results.reduce((acc, result, index) => {
        acc[index === 0 ? 'projectsByRole' : 'bulkProjects'] = result.status === 'fulfilled' ? result.value.data : [];
        return acc;
      }, {});

      return responseObject;
    });
  } catch (error) {
    return onApiError(thunkApi, error);
  }
});

const extractMessageFromResponse = data => {
  if (data.message) {
    return data.message;
  } else if (data.error) {
    return data.error;
  } else {
    return JSON.stringify(data);
  }
};

const getErrorMessage = error => {
  if (axios.isAxiosError(error)) {
    if (error.response) {
      return extractMessageFromResponse(error.response.data);
    } else {
      return error.message || JSON.stringify(error);
    }
  } else {
    return JSON.stringify(error);
  }
};

function onApiError(thunkApi, error) {
  console.log(error);
  const message = getErrorMessage(error);
  return thunkApi.rejectWithValue(message);
}
