import { EstimationBlock } from '../../estimation-block/EstimationBlock';
import { Box } from '@mui/system';
import cn from 'classnames';
import style from '../CalculatorBlock.module.scss';
import { EmailPopup } from '../../email-popup/EmailPopup';
import { PaymentsSchedule } from '../../paymentsSchedule/PaymentsSchedule';
import { CalculatorFreeButton } from '../../calculator-free-button/calculatorFreeButton';
import { calculatorFreeTabNew, pdfButtonInCalculatorForFree } from '../../../../utils/feature-flags';
import { checkIfCalculatorByFreeNewPropsValid } from '../../../../utils/calculatorUtils';

export const FreeEstimationBlock = ({
  propsForCalculatorByFree,
  propsForCalculatorByFreeNew,
  fetchStatus,
  activeTab,
  isEmailPopupForFreeOpen,
  setIsEmailPopupForFreeOpen,
}) => {
  const checkIfDisplayFreeEstimationBlock = () => {
    const {
      initialPayment,
      minFirstPayment,
      maxFirstPayment,
      currentBenefit,
      term,
      maxTerm,
      minTerm,
      firstPaymentCommitted,
      flatPrice,
      calcData,
    } = propsForCalculatorByFree;

    const isMonthlyPaymentValid = Boolean(calcData?.monthlyPayment);
    const isPriceWithMarginPositive = initialPayment?.priceWithMargin > 0;
    const isMinFirstPaymentValid = minFirstPayment < maxFirstPayment;
    const isFlatPriceValid = Number(flatPrice) !== 0;
    const isBenefitExists = Boolean(currentBenefit);
    const isTermValid = term >= minTerm && term <= maxTerm;
    const isFirstPaymentCommittedValid =
      firstPaymentCommitted <= maxFirstPayment && firstPaymentCommitted >= minFirstPayment;

    const isCalculationByFreePriceValid =
      isMonthlyPaymentValid &&
      isPriceWithMarginPositive &&
      isBenefitExists &&
      isMinFirstPaymentValid &&
      isFlatPriceValid;
    return isCalculationByFreePriceValid && isTermValid && isFirstPaymentCommittedValid;
  };

  const shouldDisplay = calculatorFreeTabNew
    ? checkIfCalculatorByFreeNewPropsValid({ propsForCalculatorByFreeNew })
    : checkIfDisplayFreeEstimationBlock();

  return (
    shouldDisplay && (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          height: 'fit-content',
          marginTop: '57px',
          gap: '20px',
        }}
        className={cn({ [style.wrapperStealth]: activeTab !== 'free' })}
      >
        <EstimationBlock
          {...(calculatorFreeTabNew ? propsForCalculatorByFreeNew : propsForCalculatorByFree)}
          fetchStatus={fetchStatus}
          isFreeTab={true}
        />
        {calculatorFreeTabNew && (
          <>
            {isEmailPopupForFreeOpen && (
              <EmailPopup
                onClose={() => setIsEmailPopupForFreeOpen(false)}
                onSendEmail={emailData => {
                  propsForCalculatorByFreeNew?.onSendEmail?.({
                    emailData: emailData,
                    propertyPrice: propsForCalculatorByFreeNew?.flatPriceNew,
                    initialPayment: propsForCalculatorByFreeNew?.firstPaymentCurrencyDisplayNew,
                    loanTerm: propsForCalculatorByFreeNew?.termForDisplayNew,
                    interestRate: propsForCalculatorByFreeNew?.rateNew,
                    earlyPayments: propsForCalculatorByFreeNew?.earlyPaymentsForDisplay,
                    earlyPaymentsType: propsForCalculatorByFreeNew?.earlyPaymentsType?.backendValue,
                  });
                  setIsEmailPopupForFreeOpen(false);
                }}
              />
            )}
            <PaymentsSchedule payments={propsForCalculatorByFreeNew?.freeYearlyPayments} />
            {pdfButtonInCalculatorForFree && (
              <Box display='flex' gap='16px'>
                <CalculatorFreeButton onClick={() => setIsEmailPopupForFreeOpen(true)} type='email'>
                  Отправить по e-mail
                </CalculatorFreeButton>
                <CalculatorFreeButton
                  type='pdf'
                  onClick={() =>
                    propsForCalculatorByFreeNew?.downloadPdfHandler({
                      propertyPrice: propsForCalculatorByFreeNew?.flatPriceNew,
                      initialPayment: propsForCalculatorByFreeNew?.firstPaymentCurrencyDisplayNew,
                      loanTerm: propsForCalculatorByFreeNew?.termForDisplayNew,
                      interestRate: propsForCalculatorByFreeNew?.rateNew,
                      earlyPayments: propsForCalculatorByFreeNew?.earlyPaymentsForDisplay,
                      earlyPaymentsType: propsForCalculatorByFreeNew?.earlyPaymentsType?.backendValue,
                    })
                  }
                >
                  Скачать
                </CalculatorFreeButton>
              </Box>
            )}
          </>
        )}
      </Box>
    )
  );
};
