import { useEffect } from 'react';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { Typography, Box, InputAdornment } from '@mui/material';
import { Colors } from '../../../assets/mui-styles/Colors';
import { CustomInput } from './CustomInput';
import { NumberSumFormatDefault } from './InputComponentsForInputWithSlider';

export const EarlyPaymentCard = ({
  payment,
  index,
  deletePayment,
  onChangeEarlyPayment,
  fetchStatus,
  freeYearlyPayments,
  handlePaymentError,
  earlyPaymentsErrors,
  totalCreditMonthsFree,
}) => {
  useEffect(() => {
    if (payment?.monthNumber > totalCreditMonthsFree) {
      handlePaymentError({
        id: payment?.id,
        monthNumberError: `Максимальное значение - ${totalCreditMonthsFree}`,
      });
    } else {
      handlePaymentError({
        id: payment?.id,
        monthNumberError: '',
      });
    }
  }, [totalCreditMonthsFree]);

  return (
    <Box
      key={payment?.id}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        background: Colors.additional,
        borderColor: Colors.superLightGrey,
        borderTopColor: 'transparent',
      }}
    >
      <Typography
        sx={{
          fontSize: '20px',
          fontWeight: '500',
        }}
      >
        {`Досрочный платеж ${index + 1}`}
      </Typography>

      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-start',
          marginTop: '20px',
          gap: '20px',
          alignItems: 'center',
        }}
      >
        <Box width={'40%'}>
          <CustomInput
            valueInput={payment?.sum}
            endAdornment={<InputAdornment position='end'>₽</InputAdornment>}
            label='Сумма платежа'
            inputComponent={NumberSumFormatDefault}
            onChangeInput={value => {
              onChangeEarlyPayment({
                id: payment?.id,
                key: 'sum',
                newValue: value === 0 ? '' : value,
              });
            }}
            fetchStatus={fetchStatus}
          />
        </Box>
        <Box width={'25%'}>
          <CustomInput
            valueInput={payment?.monthNumber}
            endAdornment={
              <InputAdornment position='end'>
                <BorderColorIcon
                  sx={{
                    width: '20px',
                    height: '20px',
                    color: '#302E2D61',
                  }}
                />
              </InputAdornment>
            }
            label='Номер месяца'
            onChangeInput={value => {
              if (Array.from(String(value))?.length > 3) return;
              if (isNaN(value)) return;
              onChangeEarlyPayment({
                id: payment?.id,
                key: 'monthNumber',
                newValue: value === 0 ? '' : value,
              });
            }}
            noDebounce={true}
            fetchStatus={fetchStatus}
          />
        </Box>
        <DeleteOutlineIcon
          onClick={() => deletePayment(payment?.id)}
          sx={{
            width: '20px',
            height: '20px',
            cursor: 'pointer',
          }}
        />
      </Box>
    </Box>
  );
};
