import { useCallback, useState } from 'react';
import { fetchLotCalcData } from './dataFetchers';

export const useGetLotCalcData = (
  { minFirstPayment, maxFirstPayment },
  term,
  firstPayment,
  flatPrice,
  benefit,
  { handleSetErrorStatus, handleSetPendingStatus, handleSetLoadedStatus },
) => {
  const [lotCalcData, setLotCalcData] = useState();

  const clearLotCalcData = () => setLotCalcData(null);

  const handleGetLotCalcData = useCallback(() => {
    if (
      minFirstPayment &&
      maxFirstPayment &&
      benefit?.maxYears &&
      firstPayment >= minFirstPayment &&
      firstPayment <= maxFirstPayment &&
      term <= benefit?.maxYears &&
      term >= 1 &&
      flatPrice
    ) {
      handleSetPendingStatus();
      fetchLotCalcData({
        loanTerm: term,
        mortgageId: benefit?.id,
        initialPayment: firstPayment,
        sum: flatPrice,
      })
        .then(result => {
          if (result.amountOfCredit > 0) {
            setLotCalcData(result);
            handleSetLoadedStatus();
          }
        })
        .catch(() => handleSetErrorStatus());
    }
  }, [
    benefit?.id,
    firstPayment,
    term,
    handleSetPendingStatus,
    handleSetLoadedStatus,
    handleSetErrorStatus,
    minFirstPayment,
    maxFirstPayment,
  ]);

  return { handleGetLotCalcData, lotCalcData, clearLotCalcData };
};
