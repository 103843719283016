export const mockMeetingsSchedule = {
  managers: [
    {
      id: 1,
      firstName: 'Виктор',
    },
  ],
  events: [
    {
      eventId: 1,
      managerIds: ['1'],
      type: 'meeting',
      startTime: '10:00',
      duration: 90,
      eventData: {
        name: 'Александр',
        room: 'R5',
        isOnline: false,
      },
    },
    {
      eventId: 4,
      managerIds: ['1'],
      type: 'lunch',
      startTime: '10:00',
      duration: 60,
      eventData: null,
    },
    {
      eventId: 4,
      managerIds: ['1', '7', '15'],
      type: 'team-sync',
      startTime: '10:00',
      duration: 60,
      eventData: null,
    },
    {
      eventId: 7,
      managerIds: ['1'],
      type: 'vacation',
      startTime: null,
      eventData: null,
    },
    {
      eventId: 18,
      managerIds: ['1'],
      type: 'weekend',
      startTime: null,
      eventData: null,
    },
  ],
};

export const mockOperatingMode = {
  schedule: {
    userId: 178,
    schedules: [
      {
        projectId: 380,
        scheduleId: 1,
        workingDays: ['2024-09-10', '2024-09-11'],
        startTime: '12:00',
        endTime: '20:00',
      },
      {
        projectId: 1420,
        scheduleId: 2,
        workingDays: ['2024-09-15', '2024-09-16', '2024-09-26', '2024-09-27'],
        startTime: '12:00',
        endTime: '20:00',
      },
    ],
  },
};

export const mockVacationsManager = [
  {
    vacationId: 1,
    vacationDays: ['2024-08-01', '2024-08-14'],
  },
  {
    vacationId: 2,
    vacationDays: ['2024-09-01', '2024-09-14'],
  },
  {
    vacationId: 3,
    vacationDays: ['2024-10-01', '2024-10-14'],
  },
];

// {
//     "projectId": 1246,
//     "managerId": 178,
//     "meetingDate": "2024-10-11T10:00:00Z",
//     "duration": 60,
//     "officeRoom": "S1",
//     "category": "первичная",
//     "source": "Реклама в интернете",
//     "isInterest": true,
//     "isOnline": false,
//     "contactData": {
//         "name": "Виктор",
//         "phone": 79186777440
//     }
// }
